.screen-modal-card {
  height: 80px;
  background: #F8F8F8;
  border-radius: 4px;
  border: 1px solid #EEEEEE;
  margin-right: 12px;

  padding: 16px 10px;

  .screen-modal-card-title {
    font-size: 12px;
    color: #262E3F;
    line-height: 14px;
    margin-bottom: 5px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    img {
      margin-left: 10px;
    }

    span {
      font-size: 12px;
      color: #262E3F;
    }
  }

  .screen-modal-card-number {
    font-size: 20px;
    color: #303133;
    line-height: 30px;
  }
  .screen-modal-card-unit {
    font-size: 14px;
    color: #98A0B3;
    line-height: 22px;
    margin-left: 6px;
  }

}

.screen-modal-info {
  > .ant-row {
    height: 32px;
    align-items: center;
  }
  .screen-modal-label {
    font-size: 14px;
    color: #262E3F;
    line-height: 22px;
  }
  .screen-modal-content {
    font-size: 14px;
    color: #767F95;
    line-height: 22px;
    margin-left: 10px;
  }
}

.screen-modal-title {
  font-size: 16px;
  color: #262E3F;
  line-height: 24px;
  margin-bottom: 16px;


  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-btn {
    font-size: 12px;
  }
}

.tds-content-card-title {
  font-size: 14px;
  color: #262E3F;
  line-height: 22px;
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);