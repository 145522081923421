.loginBgShadow {
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  margin-top: -80px;
  margin-left: 0;
  max-width: 624px;
  position: relative;
  :global {
    img {
      max-width: 680px;
      position: absolute;
      top: 0;
      left: -60px;
    }
    img.bg {
      mix-blend-mode: multiply;
    }
  }
}

// 登录页面外框架样式
.loginPage {
  width: 100vw;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 14px;
  height: 100vh;
  min-height: 600px;
  position: absolute;
  bottom: 0;
  background-size: 100% 100%;
  display: grid;
  :global {
    .login-wrap {
      margin: auto;
      display: flex;
      align-items: center;
      width: 100%;
      & > div {
        padding: 0px 0px 0px 0px;
        margin: 0 auto;
        width: 100%;
        .login-left {
          // padding: 40px 60px 40px 40px;
          // border-right: 1px solid #f7fdfd;
          width: 600px;
          margin: 0 auto;

          .logo {
            font-size: 22px;
            font-weight: 700;
            color: #687482;
            margin-bottom: 22px;
            img {
              width: auto;
            }
          }
          .title {
            font-weight: 500;
            color: #1cb887;
            line-height: 37px;
            font-size: 26px;
            margin-bottom: 8px;
          }
          .split-dot {
            margin: 7px 0;
            border-bottom: 3px dotted rgba(30, 198, 146, 0.3);
            width: 19rem;
          }
          .platform-desc {
            font-size: 16px;
            font-weight: 400;
            color: #6496cb;
            line-height: 22px;
          }
        }
        .desc-text {
          font-size: 16px;
        }
        .login-right {
          margin-top: 5rem;
          padding: 35px 52px 39px 54px;
          background-color: white;
          border-left: 1px solid #f7f3f3;
          box-shadow: 0px 0px 15px rgba(200, 200, 200, 0.3);
          margin: 0 auto;
          width: 496px;
          margin-top: 3rem;
          .ant-form-item-explain {
            min-height: 32px;
          }
          .ant-form-item:not(.ant-form-item-with-help) {
            margin-bottom: 32px;
          }
        }
      }
      .ant-tabs-top > .ant-tabs-nav {
        margin-bottom: 10px;
      }
      .logo {
        span img {
          zoom: 1.4;
        }
      }
    }
  }
}

// 拼图验证按钮基础样式
.verifyWrap {
  :global {
    .verify-success {
      display: block;
      height: 40px;
      line-height: 38px;
      border: 1px solid #42d4a1;
      text-align: center;
    }
    .ant-btn {
      height: 40px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      vertical-align: middle;
      align-items: center;
      background: linear-gradient(white, rgba(224, 241, 229, 0.5));
      .verify-icon {
        transform: rotate(0deg);
      }
      .verify-icon-disabled {
        filter: grayscale(1);
      }
      span {
        color: gray;
        margin-left: 1em;
        line-height: 40px;
        vertical-align: middle;
      }
      &:hover {
        background: linear-gradient(white, rgba(224, 241, 229, 0.8));
        .verify-icon {
          transition: transform 200s linear;
          transform: rotate(40000deg);
        }
        span {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}

.verifybox {
  width: 100%;
  position: absolute;
  z-index: 100;
  box-sizing: border-box;
  border-radius: 2px;
  border: 1px solid #e4e7eb;
  background-color: #fff;
  overflow: hidden;
  :global {
    .verify-bar-area {
      width: 100%;
      position: relative;
      background: #ffffff;
      text-align: center;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      border: 1px solid #ddd;
      border-radius: 4px;
      .verify-msg {
        z-index: 3;
      }
      .verify-move-block {
        position: absolute;
        top: 0px;
        left: 0;
        background: #fff;
        cursor: pointer;
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box;
        box-shadow: 0 0 2px #888888;
        border-radius: 1px;
        .verify-sub-block {
          position: absolute;
          text-align: center;
          z-index: 3;
          // /* border: 1px solid #fff; */
        }
        .verify-icon {
          font-size: 18px;
        }
      }
      .verify-move-block:hover {
        background-color: #337ab7;
        box-shadow: 0px 0px 3px #687482;
      }
    }
    .verify-tips {
      position: absolute;
      left: 0px;
      bottom: 0px;
      width: 100%;
      height: 30px;
      line-height: 30px;
      color: #fff;
      text-indent: 1em;
    }
    .suc-bg {
      background-color: rgba(92, 184, 92, 0.5);
      filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#7f5CB85C, endcolorstr=#7f5CB85C);
    }
    .err-bg {
      background-color: rgba(217, 83, 79, 0.5);
      filter: progid:DXImageTransform.Microsoft.gradient(startcolorstr=#7fD9534F, endcolorstr=#7fD9534F);
    }
    .tips-enter,
    .tips-leave-to {
      bottom: -30px;
    }
    .tips-enter-active,
    .tips-leave-active {
      transition: bottom 0.5s;
    }
    .verify-left-bar {
      position: absolute;
      top: -1px;
      left: -1px;
      background: #f0fff0;
      cursor: pointer;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      border: 1px solid #ddd;
    }
    .verify-img-panel {
      margin: 0;
      -webkit-box-sizing: content-box;
      -moz-box-sizing: content-box;
      box-sizing: content-box;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      border-radius: 3px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
      .verify-refresh {
        width: 25px;
        height: 25px;
        text-align: center;
        padding: 5px;
        cursor: pointer;
        position: absolute;
        top: 5px;
        left: 10px;
        z-index: 2000;
        .reload-icon {
          font-size: 20px;
          color: white;
          text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
          &:hover {
            color: rgba(255, 255, 255, 0.8);
          }
        }
      }
      .icon-refresh {
        font-size: 20px;
        color: #fff;
      }
      .verify-gap {
        background-color: #fff;
        position: relative;
        z-index: 2;
        border: 1px solid #fff;
      }
    }
    .verify-img-out {
      height: 200px;
    }
  }
}

.verifyPhone {
  position: relative;
  :global {
    .ant-input-suffix {
      position: absolute;
      right: 15px;
    }
  }
}

.verifyModal {
  position: unset;
}

.loginTabs {
  :global {
    .ant-tabs-nav {
      height: 40px !important;
      visibility: hidden;
      height: 20px;
    }
  }
}

.serviceModal {
  :global {
    .top-tips {
      display: flex;
      background-color: rgba(30, 198, 146, 0.06);
      padding: 7px;
      color: #1cb887;
      line-height: 24px;
      .icon {
        width: 16px;
        margin-right: 8px;
      }
    }
    .service-content {
      padding: 20px 0;
      line-height: 22px;
      h2 {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        color: #767f95;
        font-size: 14px;
      }
      ul {
        text-indent: 0;
        margin-left: 0;
      }
      .section {
        & > div {
          display: flex;
          span {
            width: 3em;
            margin-left: 2em;
          }
          p {
            flex: 1;
          }
        }
      }
    }
    .ant-modal-body {
      height: calc(100vh - 130px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #cfd6e8;
        border-radius: 6px;
      }
    }
  }
}

.forgetPage {
  :global {
    .show-pwd-icon {
      cursor: pointer;
      &:hover {
        color: #1cb887;
      }
    }
  }
}

.inviteTitle {
  margin-bottom: 11px;
  .inviter {
    font-size: 24px;
    color: #767f95;
    line-height: 32px;
    font-family: MicrosoftYaHeiSemibold;
  }
  .invited {
    font-size: 30px;
    color: #262e3f;
    line-height: 40px;
    font-family: MicrosoftYaHeiSemibold;
  }
}
.inviteTips {
  margin-bottom: 10px;
  color: #767f95;
  font-size: 16px;
}
.invite-success-modal {
  :global {
    .ant-modal-body {
      padding: 10px 20px !important;
    }
    .ant-modal-confirm-btns {
      display: none !important;
    }
    .ant-modal-confirm-content {
      margin: 0 !important;
      padding: 5px 0 5px 28px !important;
    }
  }
}
.invite-success-modal-title {
  padding: 4px 0;
  img {
    width: 18px;
    margin-right: 10px;
  }
  span {
    font-size: 16px;
    color: #262e3f;
    line-height: 24px;
  }
}
