.workspace-layout {
  background: url('../dotted.svg') repeat;
  background-size: 80px 80px;
  padding: 0.5rem 1rem;

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }

  .topology-area {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 800px;
  }

  .topology-area-top {
    top: 0;
    transform: translateY(0);
  }

  .ant-card-head {
    padding: 0 16px !important;
    min-height: 40px;
  }

  .ant-card-extra {
    padding: 12px 0;
  }

  .ant-card-head-title {
    font-size: 14px;
    color: #262e3f;
    font-weight: 500;
    line-height: 40px;
    padding: 0 !important;
  }
}

.site-layout-background {
  .ant-card {
    border-radius: 0.5rem;
    margin-top: 0.5rem;
  }
}

.site-drawer-render-in-current-wrapper {
  position: relative;
  height: 200px;
  padding: 48px;
  overflow: hidden;
  text-align: center;
  border: 1px solid #ebedf0;
  border-radius: 2px;
}

.no-drawer-boxshadow {
  .ant-card-head {
    min-height: 30px;
  }
  .ant-card-extra {
    padding: 0;
  }
  .ant-drawer-content-wrapper {
    box-shadow: none !important;
    .ant-drawer-content {
      background-color: transparent;
    }
  }
  .ant-card-head-title {
    padding: 0.7rem 0;
  }
}

.title-span {
  font-weight: 600;
}

.square-point {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 1rem;
  margin-right: 5px;
}

.start-point {
  background-color: @primary-color;
}

.end-point {
  background-color: @blue-color;
}

.start-label {
  color: @primary-color;
}

.end-label {
  color: @blue-color;
}
.cross-label {
  color: gray;
  font-size: 14px;
}

.arrow-right-label {
  color: gray;
  transform: scaleY(70%);
}

.inner-config-content {
  align-items: center;
  margin-bottom: 8px;

  > .ant-col {
    > * {
      width: 100%;
    }
  }
}

.full-filled {
  width: 100%;
  > span {
    display: inline-block;
    width: 100%;
    &.ant-checkbox,
    &.ant-radio {
      display: inline;
      width: auto;
    }
  }
}

.vertical-align {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }

  span {
    margin-left: 0 !important;
    font-size: 12px;
    line-height: 14px;
    margin-top: 6px;
  }
}

.icon-selected {
  svg {
    color: @primary-color;
  }

  .delete-icon {
    svg {
      color: white !important;
    }
  }
}

.overlay-inner {
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-col {
    padding: 0;
  }
}

.custom-layout {
  .ant-card-body {
    padding: 16px;
  }
}

.pv-config-layout {
  .ant-card-head-title {
    font-weight: 400;
    color: #262e3f;
  }

  .model-select {
    font-size: 12px;
    color: #262e3f;
  }

  .tips {
    color: #98a0b3;
    font-size: 12px !important;
  }
}

.system-form {
  .ant-form-item {
    align-items: center;
    margin-bottom: 2px !important;
  }
  .ant-row {
    margin-bottom: 1rem;
  }
  h3 {
    font-weight: bold;
  }
}

.total-rate {
  padding-top: 1rem;
  border-top: 1px dotted #ebebeb;

  h3 {
    background-color: #f7f6f8;
    line-height: 40px;
    padding: 0 24px;
    font-weight: bold;

    span {
      font-size: 24px;
      color: @primary-color;
    }
  }
}

.tips {
  font-size: 12px;
  color: #98a0b3;
  line-height: 22px;
}

.custom-height-table {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 8.5px 16px;
  }
}

.content-value {
  font-size: 12px;
  color: #767f95;
  line-height: 22px;
  text-align: right;
}

.sub-title {
  color: #262e3f;
  font-size: 16px;
}

.tips {
  font-size: 14px;
  color: #767f95;
  margin: 10px 0;
}

.disabled-no-style {
  .ant-select-selector,
  .ant-input,
  .ant-input-affix-wrapper-disabled {
    background-color: white !important;
  }
}

.line-tooltip {
  margin: 0 0 0 6px;

  .ant-slider-handle {
    &:before {
      content: '';
      width: 0;
      opacity: 0;
      border-left: 1px dashed #98a0b3;
      position: absolute;
      bottom: 14px;
    }
  }

  .ant-slider-dot {
    background-color: rgba(30, 198, 146, 0.2);
    border-color: rgba(30, 198, 146, 0.2);
    width: 4px;
    height: 4px;
    top: 0;
  }

  .ant-slider-dot-active {
    background-color: rgba(255, 255, 255, 0.4);
    border-color: rgba(255, 255, 255, 0.4);
  }
}

.info-title {
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #262e3f;
  line-height: 40px;
  &-record {
    font-size: 14px;
    color: #1cb887;
    cursor: pointer;
    margin-right: 22px;
  }
}
.photovoltaicInfo-flex {
  display: flex;
  justify-content: space-between;
}
.info-content {
  font-size: 20px;
  font-weight: 500;
  color: #262e3f;
  line-height: 20px;
}
.info-label {
  font-size: 12px;
  font-weight: 400;
  color: #98a0b3;
  line-height: 18px;
}
.info-unit {
  font-size: 10px;
  font-weight: 400;
  color: #767f95;
  line-height: 14px;
}
.info-body {
  padding: 16px 0;
  border-bottom: 1px solid #ebebeb;

  &:last-child {
    border-bottom: none;
  }

  .result-title {
    height: 40px;
    background: #f7f6f8;
    border-left: 3px solid rgba(30, 198, 146, 1);
    font-size: 16px;
    font-weight: bold;
    color: #262e3f;
    line-height: 40px;
    padding-left: 16px;
  }

  .result-sub-title {
    margin-top: 8px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    font-weight: bold;
    color: #262e3f;
    line-height: 22px;
  }

  .result-charts {
    margintop: 8px;
    padding-left: 16px;
    padding-right: 16px;
    height: 180px;
    width: 100%;
  }
}

.info-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 78px;
}

.line-tooltip-1 {
  .ant-slider-handle {
    &:before {
      opacity: 1;
      height: 160px;
    }
  }
}

.line-tooltip-2 {
  .ant-slider-handle {
    &:before {
      opacity: 1;
      height: 310px;
    }
  }
}
.config-main-body {
  padding: 10px 16px 10px 16px;

  .ant-card-head-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .card-tips {
    font-size: 12px;
    color: #767f95;
  }
}

.no-padding-card {
  > .ant-card-head {
    padding: 0 !important;
  }

  > .ant-card-body {
    padding: 0;
  }

  .inner-config-content {
    font-size: 12px;
    color: #262e3f;

    .inner-config-value {
      font-size: 12px;
      color: #767f95;
      text-align: right;
      display: inline-block;
      width: 100%;
    }
  }
}

.only-view-mode {
  .ant-input-affix-wrapper {
    border: none;
    pointer-events: none;
    input {
      text-align: right;
    }
  }
}

.show-delete-button {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .delete-icon {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);

    &:hover {
      opacity: 1;
    }
  }
}

.table-minimal {
  .ant-table-cell {
    padding-top: 9px;
    padding-bottom: 9px;
  }

  border-bottom: 1px solid #ebebeb;
}

.custom-tab-button-selected {
  background-color: white !important;
  font-weight: bold;
}

.no-padding-card {
  .custom-card {
    .ant-card-head {
      padding: 0 24px !important;
      min-height: 40px;
      border-bottom-style: none;
    }
    .ant-card-body {
      padding-left: 24px !important;
      padding-right: 24px !important;
    }
  }
}

.dotted-line {
  margin-bottom: 16px;
  height: 1px;
  width: 100%;
  background-image: linear-gradient(to right, #ebebeb 0%, #ebebeb 50%, transparent 50%);
  background-size: 8px 2px;
  background-repeat: repeat-x;
}

.result-config {
  .info-body {
    border-bottom: none;
    padding-bottom: 0;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);