.header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
  height: 60px;
  .user-info {
    display: flex;
  }
}

.site-layout-background {
  overflow: hidden;
  > div {
    height: 100%;
    max-height: 100%;
  }

  > .no-overflow-content {
    overflow: hidden;
  }
}

button.actived {
  border: 1px solid rgb(66, 212, 161);
  color: rgb(66, 212, 161);
}
button.actived:hover {
  filter: opacity(0.7);
}

.ant-modal-confirm-confirm .ant-modal-confirm-body-wrapper .ant-modal-confirm-body > .anticon {
  line-height: 1;
}

.amap-marker-label {
  border: none;
  background-color: transparent;
}
.amap-marker {
  .amap-marker-label {
    display: none;
  }
  &:hover {
    .amap-marker-label {
      display: block;
    }
  }
}

.max-one-row {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.max-two-row {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.klf-custom-menu {
  width: 216px;

  overflow-y: auto;
  padding-bottom: 100px;

  .ant-menu-submenu {
    background-color: white;
  }

  .ant-menu-sub.ant-menu-inline {
    background-color: white;
  }
  &.ant-menu-root {
    .ant-menu-title-content {
      font-size: 16px;
      font-weight: bold;
    }
    .ant-menu-sub .ant-menu-title-content {
      font-size: 14px;
      font-weight: normal;
    }

    &.ant-menu-inline-collapsed {
      li {
        text-align: center;

        svg {
          margin-left: -6px !important;
        }

        .custom-icons-box {
          padding-top: 4px;
          margin-left: -6px !important;
        }
        .custom-icons-box svg {
          margin-left: 0 !important;
        }
      }
    }
    &.ant-menu-inline {
      .ant-menu-item,
      .ant-menu-submenu-title {
        width: 184px;
        margin: 0 auto !important;
        height: 32px;
        padding-left: 0 !important;
        border-radius: 4px;
        background-color: white;
        font-size: 14px;
      }

      .ant-menu-item-selected {
        width: 184px;
        background: #1ec692 !important;

        svg {
          color: white;
        }

        a {
          color: white !important;
        }

        &:after {
          display: none;
        }
      }

      .setting-line {
        margin: 4px auto !important;
        margin-left: 16px !important;
        border-radius: 4px;
        background-color: white;
        padding-right: 16px;
        .anticon {
          text-indent: 8px;
        }
      }

      .collspaned-menu-open {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        right: 2rem !important;
      }
    }
    .ant-menu-item-only-child {
      .ant-menu-title-content {
        padding-left: 2px;
      }
    }
    .ant-menu-item-only-child.collspaned-menu {
      padding-left: 20px;
    }
    .setting-line {
      padding-left: 20px;
      padding-right: 15px;
      .anticon {
        font-size: 18px;
      }
      &.inline-collapsed-setting {
        &.ant-menu-item-selected {
          padding: 0;
          font-size: 18px;
          padding: 4px 8px;
          background-color: white;
          padding-top: 7px;
          .anticon {
            width: 32px;
            height: 32px;
            vertical-align: top;
            line-height: 18px;
            padding: 7px;
            background-color: #1ec692;
            color: white;
            border-radius: 4px;
            svg {
              margin-left: 0 !important;
            }
          }
        }
        .ant-menu-title-content {
          display: none;
        }
      }
    }
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    width: 184px;
    margin: 0 auto;
    height: 32px;
    padding-left: 40px !important;
    border-radius: 4px;
    background-color: white;
  }

  .ant-menu-inline .ant-menu-item-selected {
    width: 184px;
    background: #1ec692 !important;
    a {
      color: white !important;
    }

    &:after {
      display: none;
    }
  }
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: white !important;
}
.ant-menu-inline-collapsed {
  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    svg,
    .custom-icons-box {
      color: white;
      border-radius: 4px;
      background-color: @primary-color !important;
    }
  }
}

.custom-scroll-bar {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cfd6e8;
    border-radius: 6px;
  }
}

.verify-slider-modal {
  .anticon-close {
    color: white;
    font-size: 24px;
    top: 15px;
    position: relative;
    right: 15px;
  }
}

.custom-row-menu {
  width: 104px;
  padding: 8px 0;
  .ant-menu-item {
    height: 34px;
    margin: 0;
    line-height: 34px;
    &:hover {
      background-color: #f9f8f9;
    }
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
}

.table-creator {
  width: 96px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row-actions {
  .ant-space-item {
    & > span {
      display: inline-block;
      width: 16px;
    }
  }
}

.invite-modal {
  .ant-modal-header {
    border-bottom: 0;
  }
  .ant-modal-footer {
    border-top: 0;
  }
  .invite-header {
    display: flex;
    img {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    span {
      font-size: 16px;
      color: #262e3f;
      font-weight: 600;
      padding-right: 15px;
    }
  }
  .invite-content {
    color: #767f95;
    font-size: 14px;
    margin-bottom: 22px;
    border-radius: 4px;
  }
  .invite-checkbox-wrapper {
    height: 222px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ebebeb;
  }
  .invite-checkbox {
    width: 100%;
    .ant-radio-wrapper {
      width: 100%;
      padding: 9px 16px;
      span {
        color: #767f95;
        font-size: 14px;
      }
      &:hover {
        background-color: #f9f8f9;
      }
      &.ant-radio-wrapper-checked {
        span {
          color: #1cb887;
        }
      }
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);