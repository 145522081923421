.custom-btns-ml4{
    &.ant-btn>span:last-child{
      margin-left: 4px;
    }
  }

.electricity-info-card{
  .ant-card-head{
    .ant-card-head-title{
      padding: 0;
    }
  }
}

.temp-item-btn{
  position: relative;
  display: flex;
  align-items: center;
  width: 222px;
  height: 60px;
  margin: 0 12px 24px;
  padding: 4px 16px;
  line-height: 24px;
  border-radius: 4px;
  white-space: nowrap;
  
  strong{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
  .ant-btn{
    opacity: 0;
  }
  &:hover{
    color: rgba(0, 0, 0, 0.65);
    .ant-btn{
      opacity: 100;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)
    }
  }
}
@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);