
.main{
    overflow:auto;
}

// 隐藏滚动条
.main::-webkit-scrollbar{
    display: none;
} 

//横排
.horizontalCenter{
    display: flex;
    justify-content: center;
    align-items: center;
}

//竖排
.verticalCenter{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dataPanel{
    background: #fff;
    border-top: 1px solid #fff;
    height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding:10px;
    
    .box{
        margin: auto;
        display: -webkit-flex;
		display: flex;
		justify-content: center;
        align-items: center;
        .content{
            margin-left: 10px;
        }
        .name{
            font-size: 16px;
            font-weight: 500;
            color: rgba(107,107,107,.85);
        }
        .value{
            font-size: 24px;
            margin-top: 10px;
            font-weight: 500;
            color: rgba(0,0,0,.85);
        }
        .unit{
            font-size: 14px;
            color: rgba(0,0,0,.85);
            font-style: normal;
        }
    } 

    .item{
        display: -webkit-flex;
		display: flex;
        .itemBox{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: 120px;
        }

        .itemBox:nth-child(n+2){
            border-left: 1px solid #e1dfdf;
        }

        .name{
            font-size: 14px;
            font-weight: 500;
            color: rgba(0,0,0,.45);
        }
        .value{
            font-size: 21px;
            color: rgba(0,0,0,.65);
        }
        .unit{
            font-size: 14px;
            font-weight: 400;
            color: rgba(0,0,0,.65);
            font-style: normal;
        }
    }
   
}

.titlePanel{
    width: 100%;
    height: 55px;
    line-height: 55px;
    padding-left: 20px;
    border-bottom: 1px solid #e8e8e8;
    font-size: 16px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 500;
    color: #262e3f;
    background-color: #fff;
}

.bgChartsPanel{
    background: #fff;
    border-top: 1px solid #fff;
    height: 500px;
    .content{
        height: calc(500px - 55px);
    }
}

.smChartsPanel{
    background: #fff;
    border-top: 1px solid #fff;
    height: 450px;
    .content{
        height: calc(450px - 55px);
    }
}

.dataUnit{
    width: 170px;
    height: 68px;
    margin: 0px 10px;
    border: 1px solid rgb(168, 216, 201);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name{
        color: rgb(109, 109, 109);
        font-size: 16px;
        font-weight: 500;
    }
    .value{
        font-size: 18px;
        font-weight: 500;
        color: rgb(30, 198, 146);
        .unit{
            font-size: 14px;
        }
    }
}

.dataRow{
    font-size: 14px;
    .dots1{
        color: rgb(59, 160, 255);
    }
    .dots2{
        color: rgb(250, 217, 84);
    }
    .item{
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
    }
    .separation{
        color: rgb(217, 217, 217);
    }
    .percentage{
        color: rgba(0, 0, 0, 0.45);
    }
}