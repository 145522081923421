.tds-main {
  height: 100%;
  display: flex;
  flex-direction: column;

  * {
    flex-shrink: 0;
  }

  .tds-cards {
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;

    .tds-card {
      flex-grow: 1;
      height: 76px;
      background-color: white;
      border-radius: 4px;
      margin-right: 16px;
      padding: 12px 9px;

      &:last-child {
        margin-right: 0;
      }

      display: flex;
      flex-direction: row;

      .tds-card-icon {
        width: 26px;
        height: 26px;
        margin-right: 5px;
      }

      .tds-card-body {
        display: flex;
        flex-direction: column;

        .tds-card-title {
          font-size: 12px;
          color: #262E3F;
          line-height: 14px;
          margin-bottom: 4px;
          margin-top: 4px;
        }

        .tds-card-content {
          font-size: 20px;
          color: #303133;
          line-height: 30px;

          .tds-card-unit {
            margin-left: 4px;
            font-size: 14px;
            font-weight: 400;
            color: #98A0B3;
            line-height: 22px;
          }
        }
      }
    }
  }

  .tds-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 66%;

    .tds-left-content {
      display: flex;
      flex-direction: column;

      .tds-stations {
        > div {
          background-color: white;
          border-radius: 4px;
          width: 100%;
          height: 100%;
        }

        flex-grow: 1;
      }

      .tds-monitor {
        margin-top: 16px;

        > div {
          background-color: white;
          border-radius: 4px;
          width: 100%;
          height: 100%;
        }

        flex-grow: 1;
      }

    }

    .tds-right-content {
      padding-left: 16px;
      display: flex;
      flex-direction: column;

      .tds-map {
        > div {
          background-color: lightgrey;
          border-radius: 4px;
          width: 100%;
          height: 100%;
          position: relative;
          overflow: hidden;
        }

        flex-grow: 1;

        .t-screen-rank {
          position: absolute;
          right: 0;
          top: 0;
          width: 360px;
          height: 320px;
          background-color: white;
          border-radius: 4px;
        }
      }
    }
  }

  .tds-footer {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    height: 33%;

    .tds-contribute {
      > div {
        background-color: white;
        border-radius: 4px;
        width: 100%;
        height: 100%;
      }
    }

    .tds-charts {
      padding-left: 16px;

      > div {
        background-color: white;
        border-radius: 4px;
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
      }
    }
  }

  .tds-content-card-title {
    font-size: 14px;
    color: #262E3F;
    line-height: 40px;
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .tds-content-card-body {
    padding: 0 16px 8px 16px;
  }

  .tds-contribution-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 46px;
    background: #F8F8F8;
    border-radius: 4px;
    border: 1px solid #EEEEEE;
    margin-bottom: 16px;
    padding: 12px 9px;

    .tds-contribution-title {
      font-size: 12px;
      color: #262E3F;
      line-height: 14px;

      img {
        width: 26px;
        height: 26px;
        vertical-align: middle;
        margin-right: 4px;
      }
    }

    .tds-contribution-number {
      font-size: 20px;
      color: #303133;
      line-height: 30px;

      .tds-contribution-unit {
        font-size: 14px;
        color: #98A0B3;
        line-height: 22px;
        margin-left: 6px;
      }
    }
  }
}

#t-screen-map {
  width: 100%;
  height: 100%;
}

.t-screen-table {
  * {
    flex-grow: 0 !important;
    flex-shrink: 0;
  }
  width: 100%;
  height: 100%;

  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 20px;

  .ant-col {
    white-space: nowrap;
    padding-left: 6px;
    padding-right: 6px;
  }

  .t-screen-table-head {
    min-height: 40px;
    background: #F7F6F8;
    align-items: center;
    text-align: left;
    font-size: 12px;
    color: #262E3F;
  }

  .t-screen-table-body {
    overflow-y: auto;
    height: 204px;

    .ant-row {
      min-height: 40px;
      align-items: center;
      text-align: left;
      font-size: 12px;
      color: #262E3F;
    }

    .ant-col {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

}

.data-screen-modal {
  .ant-modal-confirm-content {
    margin: 0;
    padding: 0 !important;
  }
  .ant-modal-confirm-btns {
    display: none;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);