.button-group {
  background-color: #f8f6f9;
  padding: 0.5rem 1rem;

  button {
    margin-right: 1rem;
    background-color: #F9F8F9;
    color: #767F95;

    span {
      color: grey;
    }

    &.active {
      border-color: @primary-color;
      background-color: white;

      span {
        color: @primary-color;
      }
    }
  }
  .btn-content{
    max-width: 90%;
    display: flex;
    flex-wrap:wrap;
    a{
      margin-bottom: 0.5rem ;
    }
  }
}

.ele-form,
.bear-form,
.calc-form {
  margin-top: 2rem;

  .ant-form-item-label {
    width: 10rem !important;
    margin-right: 1rem;
  }

  .actions-footer {
    padding-left: 11rem;

    button {
      margin-right: 1rem;
    }
  }
}

.upload-template {
  border: 1px dashed #d9d9d9;
  width: 35rem;
  height: 28rem;
  padding: 1rem;

  &.upload-template-hover {
    cursor: pointer;
    &:hover {
      border-color: @primary-color;
    }
  }

  .template-init {
    width: 30rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 40px;
      height: auto;
      color: @primary-color;
    }

    p {
      margin-top: 0.5rem;
      margin-bottom: 0;
    }
  }
}

.file-dragger {
  height: 3.5rem !important;
  display: flex;
  align-items: center;
  background-color: white !important;

  .ant-upload {
    padding: 0 !important;

    .ant-upload-drag-icon {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      margin-bottom: 0 !important;
    }

    .title {
      margin-left: 1rem;
    }

    .tips {
      margin-left: 1rem;
      color: gray;
      font-size: 13px;
    }
  }
}

.timeline-dot {
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 1rem;
  font-size: 0.8rem;
  color: white;
  background-color: @border-color-base;

  margin-bottom: 4px !important;
}

.timeline-dot-finished {
  background-color: @primary-color;
}
.manage-table {
  max-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .ant-tag-green {
    padding: 4px 19px;
    border: none;
    background: rgba(30, 198, 146, 0.1);
    color: #1cb887;
    font-weight: bold;
  }

  font-size: 14px;

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 8px 16px;
  }

  .ant-table-thead > tr > th {
    padding: 16px;
  }

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }

}
.full-screen-table.right-actions .ant-pagination.ant-table-pagination.ant-table-pagination-right {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
  margin-bottom: 0;
}

.single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: inline-block;
  max-width: 100%;
}

.ant-dropdown-link {
  padding: 0 5px;
}

.full-screen-table {
  .ant-table {
    height: 100%;

    .ant-table-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      
    }
  }
}

.gaode-input {
  .ant-select-selector {
    .ant-select-selection-item {
      .hide-in-input {
        display: none;
      }
    }
  }
}

.label-top {
  .ant-form-item-label > label {
    height: 32px;
  }
}

.full-screen-table.right-actions {
  .ant-table-header {
    //overflow: inherit !important;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);