.newly-title {
    padding-left: 30px;
    margin-bottom: 20px;
}
#logistics-deliver-map {
    margin-left: 30px;
    margin-bottom: 30px;
}
#logistics-consignee-map {
    margin-left: 30px;
    margin-bottom: 30px;
}
.goods-info-title {
    padding-left: 30px;
    p {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
        margin-left: 4%;
    }
}
// 查看物资详情
.logistics-details {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 30px 50px;
    overflow: auto;
    .basicInfo {
        width: 100%;
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        .basic-item {
            width: 50%;
            display: flex;
            .basic-title {
                min-width: 120px;
                border: 1px solid #d9d9d9;
                padding: 10px 0;
                margin-left: -1px;
                margin-top: -1px;
                padding-left: 20px;
                text-align: left;
                color: rgba(0, 0, 0, 0.8);
            }
            .basic-dataIndex {
                flex: 1;
                border: 1px solid #d9d9d9;
                padding: 10px 0;
                margin-left: -1px;
                margin-top: -1px;
                box-sizing: border-box;
                padding-left: 20px;
            }
        }
        .basic-total-item {
            width: 100%;
            display: flex;
            .basic-total-title {
                min-width: 120px;
                border: 1px solid #d9d9d9;
                padding: 10px 0;
                margin-left: -1px;
                margin-top: -1px;
                padding-left: 20px;
                text-align: left;
                color: #1ec692;
                font-size: 16px;
            }
            .basic-total {
                flex: 1;
                border: 1px solid #d9d9d9;
                padding: 10px 0;
                margin-left: -1px;
                margin-top: -1px;
                box-sizing: border-box;
                padding-left: 20px;
                font-size: 16px;
                color: #1ec692;
            }
        }
        
    }
    .basic-logistics-back {
        width: 504px;
        height: 574px;
        background: url('./images/logisticsBack.png') no-repeat;
        background-size: 100% 100%;
    }
    .basic-side {
        margin-top: 30px;
    }
    .basic-back {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
    .basic-remarks {
        margin-top: 30px;
        .remarks-text {
            line-height: 20px;
        }
    }
    .wl-table {
        padding: 0;
    }
}

.logistics-create {
    .ant-upload.ant-upload-select-picture-card {
        background-color: rgba(0, 0, 0, 0);
        border: none;
    }
    .ant-typography.ant-typography-secondary {
        position: absolute;
        bottom: -36px;
        transform: translateY(-50%);
        left: 0;
    }
}
@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);