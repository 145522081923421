.rights-template {
  background-color: white;
  padding: 2em;
  border-radius: 4px;
  &-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    h3 {
      font-size: 20px;
      font-weight: 700;
      color: #262e3f;
      line-height: 28px;
    }
  }
  &-form {
    width: 100%;
    .ant-input {
      width: 100% !important;
    }
  }
  &-detail {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    height: calc(100vh - 240px);
    overflow: hidden;
    &-header {
      margin-bottom: 12px;
    }
    &-content {
      position: relative;
      flex: auto;
      overflow: hidden;
    }
    &-footer {
      background-color: #ffffff;
      position: absolute;
      left: -24px;
      bottom: 0;
      width: calc(100% + 24px);
      padding: 12px 10px 12px 34px;
      box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.21);
    }
    &-rename {
      font-size: 14px;
      color: #1cb887;
      line-height: 20px;
      cursor: pointer;
    }
    &-name {
      font-size: 16px;
      color: #262e3f;
      line-height: 20px;
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);