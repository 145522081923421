.content {
  width: 100%;
  height: calc(100vh - 60px - 48px);
  flex: 1;
  background-color: #F3F4F5;
}
.equipmentHead {
  max-width: 100%;
  display: flex;
  .surveyCard {
    display: inline-table;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 16px;
    min-width: 436px;
    height: 260px;
    :global {
      .card-item {
        width: 100%;
        height: 76px;
        margin-bottom: 16px;
        display: flex;
        background-color: white;
        padding: 12px 10px;
        box-sizing: border-box;
        border-radius: 4px;
        cursor: pointer;
        .count-wrap {
          margin-left: 5px;
          .name {
            font-size: 12px;
            color: #262E3F;
          }
          .count {
            font-size: 20px;
            color: #303133;
            span {
              font-size: 14px;
              color: #98A0B3;
              margin-left: 4px;
            }
          }
        }
        img {
          height: 100%;
        }
      }
    }
  }
  
  .equipmentTypeCard {
    display: flex;
    width: calc(100vw - 436px);
    height: 260px;
    background-color: white;
    border-radius: 4px;
    margin-left: 16px;
    position: relative;
    .equipmentTypeCardTitle {
      position: absolute;
      top: 16px;
      left: 16px;
      font-size: 14px;
      color: #262E3F;
    }
  }
}
.queryWarp {
  width: 100%;
  box-sizing: border-box;
  padding: 8px 24px 24px;
  .queryItem {
    display: flex;
    align-items: center;
    margin-right: 30px;
    .queryItemTitle {
      margin-right: 14px;
      white-space: nowrap;
    }
  }
}


.measureSpotContainer {
  width: 100%;
  display: flex;
  .measureSpotLeft {
    width: 354px;
    min-height: calc(100vh - 260px - 180px);
    box-sizing: border-box;
    min-height: 100%;
    border-right: 1px solid #EBEBEB;
    .measureInput {
      width: calc(100% - 48px);
      box-sizing: border-box;
      margin: 20px 22px 10px 24px;
    }
    .measureTree {
      width: 100%;
      padding-left: 20px;
      padding-bottom: 30px;
      .siteTreeSearchValue {
        color: #f50;
      }
    }
  }
  .measureSpotRight {
    width: calc(100% - 354px);
    box-sizing: border-box;
    padding: 20px 24px;
    position: relative;
    .queryMove {
      font-size: 16px;
      color: #1CB887;
      cursor: pointer;
      position: absolute;
      right: 26px;
      top: -36px;
    }
    .queryParameterWarp {
      width: 100%;
      min-height: 90px;
      box-sizing: border-box;
      padding: 16px;
      transition: all 0.7s;
      background: #F8F8F8;
      border-radius: 4px;
      border: 1px solid #EEEEEE;
      overflow: hidden;
    }
    .queryDatePicker {
      display: flex;
      align-items: center;
      padding: 16px 0;
      .queryDatePickerTitle {
        font-size: 14px;
        color: #262E3F;
        margin-right: 20px;
      }
    }
    .queryWarp {
      padding: 16px 0;
      display: flex;
      justify-content: flex-end;
    }
    .equipmentVoltageLine {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .equipmentVoltageLineCard {
        width: calc(50% - 16px);
        min-width: 386px;
        height: 264px;
        box-sizing: border-box;
        margin: 0 8px;
        background: #FFFFFF;
        box-shadow: 0px 2px 14px 0px rgba(190,194,204,0.3000);
        margin-bottom: 16px;
      }
    }
  }
}
