
.ant-input-affix-wrapper{
    border-radius: 4px;
}

.edit-container{
    // margin-top: 30px;
    .ant-form-item-label > label{
        line-height: 32px;
        height: 32px;
    }
}
.klf-scrollbar {
    overflow-y: scroll;
}
.klf-scrollbar::-webkit-scrollbar {
    width: 10px;
    background-color: rgba(213,213,213,.08);
}
 .klf-scrollbar::-webkit-scrollbar-track {
    background-color: rgba(213,213,213,.04);
    border-radius: .5rem;
}
 .klf-scrollbar::-webkit-scrollbar-thumb {
    width: 30px;
    border-radius: .5rem;
}

.klf-scrollbar::-webkit-scrollbar-button:start {
    width: 100%;
    height: auto;
    background-size: contain;
}
.klf-scrollbar::-webkit-scrollbar-button:end {
    width: 100%;
    height: auto;
    background-size: contain;
}

.tech-rangePicker{
    .ant-picker-header-view {
        .ant-picker-year-btn{
            display: none;
        }
    }
    .ant-picker-header{
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-super-next-btn{
            display: none;
        }
    }
}

.deleted-btn{
    &.ant-btn{
        color: #98A0B3;
        &:hover{
            color: #1ec692
        }
    }
}

.fixed-label {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    .ant-form-item-label {
        flex-shrink: 0;
    }
}

.min-price-input{
    .ant-input-number-input{
        padding: 0 6px;
    }
}

.fieldsCollapse&.ant-collapse-borderless > .ant-collapse-item{
    border: 0;
    &> .ant-collapse-content > .ant-collapse-content-box{
        padding: 0;
        padding-bottom: 1px;
    }
}
.fieldsCollapse&.ant-collapse-icon-position-right {
    background: none;
    &> .ant-collapse-item > .ant-collapse-header{
        background: #fff;
        padding: 0;
        padding-right:56px;
        .ant-collapse-arrow{
            top: 17px;
            right:12px;
            font-size: 16px;
        }
        .ant-collapse-extra{
            margin-left: 14px;
        }
    }
    .ant-collapse-item-active{
        .ant-collapse-arrow{
            svg{
                transform: rotate(-90deg);
            }
        }
    }
}
@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);