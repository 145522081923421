.userCenter {
  background-color: white;
  padding: 2em;
  border-radius: 4px;
  :global {
    h1 {
      font-size: 32px;
      font-weight: 700;
    }
  }
}

.financialParams {
  :global {
    .module-item {
      padding: 10px 24px 12px 24px;
      background: #f9f8f9;
      margin-bottom: 20px;
      cursor: pointer;
      &:hover {
        background: rgba(23, 158, 117, 0.03);
        .module-title {
          color: #1cb887;
        }
      }
    }
    .module-title {
      line-height: 40px;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      display: inline-block;
      .title {
        margin-left: 12px;
      }
    }
    .list {
      .item {
        display: inline-block;
        margin-top: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        width: 184px;
        line-height: 40px;
        text-indent: 12px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #bec2cc;
        cursor: pointer;
        white-space: nowrap;
      }
    }
  }
}

.projectInvestment {
  background-color: white;
  padding: 24px;
  :global {
    h2 {
      margin-bottom: 24px;
    }
    .column-title.ant-table-cell,
    th.ant-table-cell {
      background-color: #f7f6f8;
      font-weight: 700;
    }
    th.ant-table-cell::before {
      display: none;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td.column-title {
      background-color: #f7f6f8;
    }
    .ant-table-tbody > tr.ant-table-row:hover > .odd {
      background-color: rgba(255, 255, 255, 1);
    }
    .ant-table-tbody > tr.ant-table-row:hover > .even {
      background-color: rgba(23, 158, 117, 0.03);
    }
    .odd {
      background-color: rgba(255, 255, 255, 1);
    }
    .even {
      background-color: rgba(23, 158, 117, 0.03);
    }
    td.primary-column {
      color: rgba(28, 184, 135, 1);
      line-height: 40px;
    }
    .ant-table-container table > thead > tr:first-child th.primary-column {
      text-align: left;
      padding-left: 24px;
    }
    .row-title {
      color: #262e3f;
      line-height: 22px;
      font-weight: 700;
    }
    .unit {
      font-size: 12px;
      color: #98a0b3;
      line-height: 20px;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      background-color: none;
    }
    .add-btn {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      justify-content: center;
      z-index: 1;
      background: white;
      .btn-wrap {
        background: white;
        cursor: pointer;
        padding: 20px 0;
        position: sticky;
        top: calc(50% - 40px);
        .anticon {
          color: #1cb887;
        }
        &:hover {
          .anticon {
            color: #42d4a1;
          }
        }
      }
      &::before {
        content: '';
        border-left: 1px dashed rgba(235, 235, 235, 1);
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: -1;
      }
    }
    .footer-btns {
      margin-top: 20px;
      padding-bottom: 20px;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-form-item-explain {
      display: none;
    }
    .ant-table-tbody > tr > td {
      border-bottom: 0;
    }
    .ant-table-tbody > tr > td.primary-column,
    .ant-table-tbody > tr > td.primary-column ~ td {
      border-top: 1px solid #ebebeb;
    }
    .add-wrap-hor {
      width: 100%;
      text-align: center;
      height: 56px;
      position: relative;
      .border {
        width: 100%;
        position: absolute;
        top: 27px;
        z-index: 0;
        border-top: 1px dashed rgba(235, 235, 235, 1);
      }
      .btn-wrap {
        position: relative;
        z-index: 2;
        background-color: white;
        align-items: center;
        justify-content: center;
        padding: 17px 36px;
        display: inline-block;
        cursor: pointer;
        &:hover {
          .anticon {
            color: #42d4a1;
          }
        }
        .anticon {
          color: #1cb887;
          margin-right: 8px;
        }
      }
    }
    .incentive-table-name {
      cursor: pointer;
      &:hover {
        color: #1cb887;
      }
    }
    .ant-table-wrapper {
      background-color: #f9f8f9;
      margin-bottom: 24px;
      .ant-table {
        background-color: #f9f8f9;
      }
      .ant-table-title {
        color: #262e3f;
        line-height: 21px;
        font-size: 16px;
        font-weight: 700;
      }
    }
    .ant-table-tbody > tr.ant-table-placeholder:hover > td:hover {
      background: inherit;
    }
    td.ant-table-cell .ant-table-expanded-row-fixed {
      background-color: white;
    }
    td.empty {
      display: none;
    }
    .ant-input-number-input,
    .ant-input {
      border-radius: 4px;
      height: 40px;
    }
    .ant-table-body table {
      background-color: white;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 8px 12px;
      height: 56px;
    }
    .ant-input-affix-wrapper,
    .ant-input-number-input {
      padding-top: 0;
      padding-bottom: 0;
      width: 116px;
    }
  }
}

.incentive {
  :global {
    .ant-table-expanded-row-fixed {
      display: none;
    }
    .ant-table {
      background-color: #f9f8f9;
      .ant-table-cell,
      tr {
        background-color: #f9f8f9;
        &:hover {
          background-color: #f9f8f9;
        }
      }
    }
    .ant-table-title{
      padding-left: 24px;
    }
    .ant-table-thead {
      display: none;
    }
    .ant-table-tbody > tr > td {
      border: none;
    }
    .add-row {
      &:hover {
        span {
          cursor: pointer;
          color: rgba(28, 184, 135, 1);
        }
      }
      span {
        margin-right: 5px;
        font-size: 14px;
        color: #767f95;
      }
    }
  }
}

.incentiveAddModal {
  :global {
    .ant-input-affix-wrapper.compact {
      padding: 0 0 0 11px;
      .ant-form-item {
        margin: 0;
        .ant-select-selector {
          border: none;
          border-left: 1px solid #d9d9d9;
        }
      }
    }
    .ant-form-item-label > label {
      height: 32px;
    }
    .years-wrap {
      display: flex;
      line-height: 32px;
      & > span {
        margin-left: 3px;
        margin-right: 3px;
      }
      .ant-input-number {
        width: 70px;
      }
    }
    .ant-form-item {
      margin-top: 10px;
      margin-bottom: 10px;
      .ant-form-item {
        margin: 0;
      }
    }
    .anticon-delete {
      margin-top: 10px;
    }
    .ant-btn-dashed {
      width: 100%;
    }
    .compact {
      display: flex;
    }
    .ant-input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      -moz-appearance: textfield;
    }
    .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }
    .ant-form-item-explain-error {
      display: none;
    }
    .custom-del-btn {
      font-size: 18px;
      line-height: 35px;
      margin-top: 12px;
      cursor: pointer;
    }
  }
}

.groupItem {
  background-color: #f9f8f9;
  margin-bottom: 20px;
  padding: 18px 24px;
}

.paramsColTitle {
  display: flex;
  align-items: center;
  :global {
    .col-title-content {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      height: 40px;
      line-height: 40px;

      .absolute-top {
        position: absolute;
        top: 0;
        right: 0 !important;
        font-size: 16px;
      }

      .primary-mark {
        color: #1ec692;
      }
    }
  }
}

.operaIcons {
  text-align: right;
  :global {
    svg {
      color: #1cb887;
      font-size:16px;
      cursor: pointer;
      &:hover{
          color:#42d4a1;
      }
    }
  }
}
