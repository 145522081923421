.param-config-card {
  padding-top: 12px;
  padding-bottom: 12px;
  .mb10 {
    margin-bottom: 10px;
  }
  .mb8 {
    margin-bottom: 8px;
  }

  .config-item {
    font-size: 12px;
    padding-bottom: 8px;
  }

  .param-title {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #262e3f;
    line-height: 22px;
    margin-bottom: 20px;
    .icon {
      font-size: 16px;
      margin-right: 12px;
    }
  }
  .config-main-title {
    font-size: 14px;
    font-weight: 500;
    color: #1cb887;
    line-height: 22px;
    margin-bottom: 17px;
  }
  .content {
    text-align: right;
    .number {
      font-size: 12px;
      font-weight: 500;
      color: #262e3f;
      line-height: 22px;
    }
    .unit {
      font-size: 12px;
      font-weight: 400;
      color: #98a0b3;
      line-height: 20px;
    }
    .percent {
      font-size: 12px;
      font-weight: 500;
      color: #828a9e;
      line-height: 22px;
    }
  }
  .edit-name {
    font-size: 12px;
    font-weight: 400;
    color: #767f95;
    line-height: 32px;
  }
}
.incentiveAddModal {
  .ant-form-item-label > label {
    height: 30px;
    line-height: 30px;
  }
  .ant-input-affix-wrapper.compact {
    padding: 0 0 0 11px;
    .ant-form-item {
      margin: 0;
      .ant-select-selector {
        border: none;
        border-left: 1px solid #d9d9d9;
      }
    }
  }
  .ant-form-item-label > label {
    height: 32px;
    font-size: 12px;
    color: #767f95;
    text-align: left;
    display: inline-block;
    width: 100%;
    text-indent: 10px;
    &::after {
      display: none;
    }
  }
  .years-wrap {
    display: flex;
    line-height: 32px;
    & > span {
      margin-left: 3px;
      margin-right: 3px;
    }
    .ant-input-number {
      width: 40px;
    }
  }
  .ant-form-item {
    margin-bottom: 8px;
    .ant-form-item {
      margin: 0;
    }
  }
  .anticon-delete {
    margin-top: 10px;
  }
  .ant-btn-dashed {
    width: 100%;
  }
  .compact {
    display: flex;
  }
  .ant-input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    -moz-appearance: textfield;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none;
  }
  .ant-form-item-explain-error {
    display: none;
  }

  .custom-del-btn {
    font-size: 18px;
    line-height: 35px;
    cursor: pointer;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);