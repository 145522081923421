.project-member {
  position: relative;
  height: 100%;

  &-header {
    margin-bottom: 16px;
    display: flex;

    &-left {
      flex: auto;
    }

    &-right {
      flex: auto;
      text-align: right;
    }

    &-item {
      font-size: 14px;
      color: #262e3f;
      line-height: 32px;
    }

    &-icon {
      color: #1cb887;

      &+span {
        vertical-align: middle;
      }
    }

    &-hover {
      cursor: pointer;
    }

    &-active {
      color: #1cb887;
    }
  }

  &-table {
    margin-bottom: 24px;
  }

  &-footer {
    background-color: #ffffff;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 28px 24px;
  }

  &-action {
    &-set {
      font-size: 14px;
      color: #1cb887;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }

    &-operation {
      display: inline-block;
      height: 16px;
      width: 16px;
      cursor: pointer;
    }
  }

  &-detail {
    &-header {
      margin-bottom: 12px;
    }

    &-footer {
      background-color: #ffffff;
      position: absolute;
      left: -24px;
      bottom: 0;
      width: calc(100% + 24px);
      padding: 12px 10px 12px 34px;
      box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.21);
    }

    &-name {
      font-size: 16px;
      color: #262e3f;
      line-height: 20px;
    }
  }

  &-xCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);