.content {
  width: 100%;
  height: calc(100vh - 60px - 48px);
  //padding: 20px;
  overflow-y: auto;
  background-color: #F3F4F5;
  //box-shadow: $shadow-1-right;
  //border-right: 1px solid #EBECEE;
  & > div {
    overflow-x: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: 100%;
  }
}

.leftDiv {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 600px;
  height: calc(100vh - 110px);
}

.rightDiv {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 0;
  justify-content: space-between;
  width: 360px;
  margin-left: 24px;
  height: calc(100vh - 110px);
}

.listContent {
  overflow-y: auto;
  height: calc(50vh - 140px);
}

.topListContent {
  overflow-y: auto;
  height: calc(50vh - 225px);
  position: relative;
}

.basePadding {
  padding: 20px;
}

.recentActions {
  flex-shrink: 0;
  //   height: 284px;
  margin-bottom: 20px;
  font-size: 12px;
  background-color: white;
  border-radius: 4px;
  :global {
    h1 {
      height: 22px;
      font-size: 20px;
      font-weight: bold;
      color: #262e3f;
      line-height: 22px;
      margin-bottom: 20px;
    }
    .ant-radio-group {
      display: flex;
      margin-bottom: 16px;
    }
    .ant-radio-button-wrapper {
      border-radius: 4px;
      border: 1px solid white;
      width: 80px;
      text-align: center;
      font-size: 12px;
      &::before {
        display: none;
      }
    }
    .ant-radio-button-wrapper-checked {
      border-radius: 4px;
      border: 1px solid #1ec692;
    }
    .ant-list-item {
      padding-left: 15px;
      padding-right: 15px;
      border-bottom: none;
      color: #262e3f;
    }
    .ant-list-item.title {
      height: 40px;
      line-height: 40px;
      font-weight: 700;
      background-color: #f7f6f8;
      font-size: 12px;
      color: #262e3f;
      line-height: 16px;
    }
  }
}

.statisticsCard {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
  height: 94px;
  :global {
    .card-item {
      margin-bottom: 20px;
      display: flex;
      background-color: white;
      padding: 7px 18px 7px 24px;
      border-radius: 4px;
      cursor: pointer;
      justify-content: space-between;
      .count {
        font-size: 28px;
        font-weight: 700;
      }
      .name {
        font-size: 12px;
        color: #606266;
      }
      img {
        height: 100%;
      }

      &:hover {
        background-color: #eceef1;
      }
    }
  }
}

.projectMap {
  background-color: white;
  flex: 1;
  min-height: calc(100% - 120px);
  margin-top: 24px;
  :global {
    .amap-info-content {
      background-color: #262e3f;
      opacity: 0.95;
      color: white;
      padding: 10px;
      border-radius: 5px;
      .amap-info-close {
        display: none;
      }
    }
    .bottom-center .amap-info-sharp,
    .bottom-center .amap-info-sharp::after {
      border-top: 8px solid rgba(38, 46, 63, 0.95);
    }
    .map-project-name {
      text-align: center;
    }
  }
}

.activityLogs {
  :global {
    .ant-list-items > .ant-list-item {
      padding-left: 0;
    }
    .log-row{
      word-break: break-all;
    }
  }
}
