.cost-modal-wrap {
  .ant-modal-header {
    border: none;
  }
  .title {
    font-size: 14px;
    color: #767f95;
    line-height: 22px;
    margin-top: 6px;
    margin-bottom: 13px;
  }
  .cost-modal-content {
    > div:last-child {
      margin-bottom: 49px;
    }
    .module-item {
      padding: 10px 24px 4px 24px;
      background: #f9f8f9;
      margin-bottom: 20px;
      cursor: pointer;
      &:hover {
        background: rgba(23, 158, 117, 0.03);
        .module-title {
          color: #1cb887;
        }
      }
    }
    .module-title {
      line-height: 40px;
      font-size: 16px;
      font-weight: 700;
      cursor: pointer;
      display: inline-block;
      .title {
        margin-left: 12px;
      }
    }
    .list {
      .item {
        display: inline-block;
        margin-top: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        width: 184px;
        line-height: 40px;
        text-indent: 12px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #bec2cc;
        cursor: pointer;
        white-space: nowrap;
      }
      .checked {
        background: rgba(30, 198, 146, 0.04);
        border-color: ' #1EC692;';
      }
    }
    .absolute-bottom {
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }
  .btns {
    text-align: right;
  }
  .ant-btn.actived:focus {
    border: 1px solid #42d4a1;
    color: #42d4a1;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);