.full-filled-card {
  .ant-card-head-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .add-cost-button {
    width: 36px;
    height: 36px;
    background: #1ec692;
    border-radius: 50%;
    color: #fff !important;
    font-size: 10px;
  }
  .ant-card-head-title {
    .zhankai-icon {
      transform: rotate(-90deg);
      cursor: pointer;
      font-size: 16px;
      color: #a2a9ba;
    }
  }
}

.small-th {
  .ant-table-thead > tr > th {
    padding-top: 6px;
    padding-bottom: 6px;

    .ant-table-column-title {
      line-height: 26px;
    }
  }
}

.no-border {
  .ant-table-row {
    .hover-display {
      opacity: 0;
    }
    &:hover .hover-display {
      opacity: 1;
    }
  }

  .ant-table-body {
    padding-bottom: 1px;

    .ant-table-cell {
      padding-left: 0;
      padding-right: 1rem;

      &:first-child {
        padding-left: 1rem;
      }
    }
  }
}

.config-section {
  margin-top: 10px;
  &:first-child {
    margin-top: 0;
  }
}

.config-title {
  font-size: 16px;
  color: #262e3f;
  // line-height: 21px;
  line-height: 32px;
  font-weight: bold;
  .hover-icon {
    display: none;
  }

  .normal-icon {
    display: inline-block;
  }
}
.config-container {
  display: flex;
  align-items: center;
  padding-right: 16px;
  justify-content: space-between;
  cursor: pointer;
  .config-del-btn {
    font-size: 16px;
    color: @primary-color;
    display: none;
  }
}
.config-container:hover {
  background-color: #f9f8f9;
  .config-tips {
    color: @primary-color;
    transition-duration: 0s;
    background-color: transparent;
  }
  .config-del-btn {
    display: block;
  }
}

.config-tips {
  font-size: 14px;
  color: #767f95;
  line-height: 19px;
  padding-left: 28px;
  // margin-top: 12px;

  // &:hover {
  //   color: @primary-color;

  //   .hover-icon {
  //     display: inline-block;
  //   }

  //   .normal-icon {
  //     display: none;
  //   }
  // }
}

.one-line {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
}

.tech-plan-cascader {
  .ant-cascader-menu {
    position: relative;
  }
}
.edit-config-button {
  position: relative;
  z-index: 2;
}
.tech-cascader-button {
  position: absolute;
  z-index: 1;
  width: 156px;
  text-align: right;
  right: 10%;
}
.ant-table-cell {
  position: relative;
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);