.project {
  &-permission {
    position: relative;
    height: 100%;
    &-box {
      display: block;
      height: 100%;
      // padding-bottom: 30px;
      // overflow-y: auto;
    }
    &-table {
      .ant-table-cell {
        vertical-align: top;
      }
    }
    &-footer {
      background-color: #ffffff;
      position: absolute;
      left: -24px;
      bottom: 12px;
      width: calc(100% + 24px);
      padding: 12px 10px 12px 34px;
      box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.21);
    }
    &-row {
      &-list {
        width: 100%;
      }
      &-item {
        padding: 16px 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
        .ant-checkbox-wrapper {
          min-width: 140px;
          margin-right: 0;
        }
      }
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);