#main{
    width: 900px;
    height: 900px;
}
.update-time-line{
    position: relative;
    height: 80px;
    padding-right: 15px;
    line-height: 40px;
    &::before{
        content: " ";
        width: 4px;
        background: #CFD6E8;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 2px;
    }
}
.custom-visual-map{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: 270px;
    left: 751px;
}
.visual-map-box{
    width: 12px;
    height: 120px;
    border-radius: 2px;
}
@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);