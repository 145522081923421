.wl-table-page {
  height: 100%;

  .ant-card-body {
    padding: 0;
  }
}

.wl-table {
  max-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  font-size: 14px;

  padding: 0 24px;
  height: calc(100% - 24px);
  position: relative;
  overflow: auto;

  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }

  .ant-table {
    height: calc(100% - 56px);
  }

  .ant-pagination-options {
    order: -1;
    flex-grow: 1;
  }

  .ant-pagination.ant-table-pagination.ant-table-pagination-right .ant-pagination-total-text {
    order: -1;
    flex-grow: 0;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 8px 16px;
  }

  .ant-statistic-content {
    font-size: inherit;
    color: inherit;
  }

  .ant-statistic-content-prefix {
    margin-right: 0;
  }
}

.table-action-popover {
  .ant-menu-vertical > .ant-menu-item,
  .ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 34px;
    line-height: 34px;
    margin: 0;
    padding: 0 20px;
    min-width: 90px;
    text-align: center;

    &:hover {
      background-color: #f9f8f9;
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);