.manage-table {
  max-height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .ant-tag-green {
    padding: 4px 19px;
    border: none;
    background: rgba(30, 198, 146, 0.1);
    color: #1CB887;
    font-weight: bold;
  }

  font-size: 14px;

  .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 8px 16px;
  }

  .ant-table-thead > tr > th {
    padding: 16px;
  }

  .ant-spin-nested-loading,.ant-spin-container {
    height: 100%;
  }

  .ant-pagination.ant-table-pagination.ant-table-pagination-right {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
  }
}

.single-line {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: inline-block;
  max-width: 100%;
}

.ant-dropdown-link {
  padding: 0 5px;
}

.full-screen-table {
  .ant-table {
    height: 100%;

    .ant-table-container {
      height: 100%;
      display: flex;
      flex-direction: column;

      .ant-table-body {
        //flex-grow: 1;
      }
    }
  }
}

.gaode-input{
  .ant-select-selector {
    .ant-select-selection-item {
      .hide-in-input {
        display: none;
      }
    }
  }
}

.label-top {
  .ant-form-item-label > label {
    height: 32px;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);