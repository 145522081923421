.add-members {
  &-modal {
    .ant-modal-body {
      padding: 20px 24px;
    }
  }

  &-content {
    display: flex;
    height: 338px;
    margin-bottom: 20px;
    border-radius: 4px;
    border: 1px solid #ebebeb;
  }

  &-list {
    width: 233px;
    flex: 0 0 232px;
    padding: 16px;
    border-right: 1px solid #ebebeb;

    &-header {
      margin-bottom: 16px;
    }

    &-content {
      height: calc(100% - 56px);
      overflow-y: auto;
    }
  }

  &-item {
    padding: 10px 0 10px 20px;

    &:hover {
      background: #f9f8f9;
      border-radius: 8px;
    }

    .ant-checkbox-wrapper {
      display: flex;
      align-items: center;

      .ant-checkbox+span {
        flex: auto;
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    &-avatar {
      margin-left: 8px;
      margin-right: 8px;
      background: #ddd;
      padding: 3px;
      border-radius: 100%;
      color: #fff;
      font-size: 13px;
    }

    &-name {
      flex: auto;
      margin: 0;
      font-size: 14px;
      color: #262e3f;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &-selected {
    flex: auto;
    padding: 16px;

    &-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      padding: 7px 11px;
      font-size: 14px;
      color: #262e3f;
      line-height: 22px;
    }

    &-clear {
      color: #1cb887;
      cursor: pointer;
    }

    &-content {
      height: calc(100% - 56px);
      overflow-y: auto;

      .ant-table-cell {
        padding: 10px 24px;
      }
    }
  }

  &-link {
    &-title {
      margin-bottom: 12px;
      font-size: 16px;
      color: #262e3f;
      line-height: 22px;
    }

    &-create {
      display: flex;
      height: 40px;
      line-height: 40px;
      margin-bottom: 8px;
      border-radius: 4px;
      border: 1px solid #ebebeb;

      .ant-select {
        flex: auto;
      }

      &-btn {
        padding: 10px 18px;
        font-size: 14px;
        color: #767f95;
        line-height: 20px;
        border-left: 1px solid #ebebeb;
        cursor: pointer;
        width: 96px;

        &:hover {
          background: rgba(0, 0, 0, 0.018);
          border-color: transparent;
        }

        &:active {
          background: rgba(0, 0, 0, 0.028);
          border-color: transparent;
        }
      }
    }

    &-option {
      font-size: 14px;
      color: #262e3f;

      &-prefix {
        color: #767f95;
      }
    }

    &-tips {
      font-size: 12px;
      color: #98a0b3;
      line-height: 18px;
      margin-bottom: 0;
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);