
.main{
    overflow:auto;
}

// 隐藏滚动条
.main::-webkit-scrollbar{
    display: none;
} 

.mapPanel{
    width: 100%;
    height: 510px;
    background: #fff
}

.capacityPanel{
    margin: 10px 0px 10px 0px;
    width: 100%;
    height: 250px;
    background-color: #fff;
}

.chartPanel{
    margin:0px 0px 10px 10px;
    width: 100%;
    height: 250px;
    background: #fff;
}

.titlePanel{
    width: 100%;
    height: 55px;
    line-height: 55px;
    padding-left: 20px;
    border-bottom: 1px solid #e8e8e8;
    font-size: 16px;
    font-family: PingFangSC-Medium,PingFang SC;
    font-weight: 500;
    color: #262e3f;
    background-color: #fff;
}

.dataUnit{
    width: 100%;
    height: auto;
    margin-top: 19px;
    font-size: 16px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: rgba(0,0,0,.45);
    text-align: center;
    .value{
        font-size: 24px;
        font-family: HelveticaNeue;
        color: rgba(0,0,0,.85);
        margin-top: 5px;
    }
    .unit{
        font-style: normal;
        font-size: 16px;
    }
}

.filterBtn{
    width: 52px;
    height: 32px;
    border-radius: 2px;
    margin-top: 5px;
    border: 1px solid #9d9d9d;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    cursor: pointer;
    color: #454545;
}

.activeBtn{
    background: #1ec692;
    color: #fff;
    border: 1px solid #1ec692;
}