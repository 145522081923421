.card-title {
  display: flex;
  
  h3 {
    margin: 0 0 0 14px;
    line-height: 36px;
    font-size: 20px;
    font-weight: 500;
    color: #262e3f;
  }
  &-back {
    width: 36px;
    height: 36px;
    text-align: center;
    background: #ffffff;
    border-radius: 500%;
    box-shadow: 0px 0px 9px -2px #bec2cc;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: #1ec692;
    img {
      width: 18px;
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);