.c-electric {
  position: relative;
  padding: 0 20px;
  &-timeline {
    width: 100%;
    height: 8px;
    background: #f7f6f8;
    border-radius: 5px;
    //overflow: hidden;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 8px;
    &-circle {
      width: 8px;
      height: 8px;
      background: rgba(30, 198, 146, 0.2);
      border-radius: 4px;
      display: inline-block;
      z-index: 100;
      position: absolute;
    }
    &-item {
      display: inline-block;
      position: absolute;
      height: 8px;

      opacity: 0.5;

      transition: all 0.3s ease;
      border-radius: 4px;

      &:hover {
        opacity: 1;
        z-index: 99;
        box-shadow: 0px 0px 5px 1px grey;
      }
    }
  }

  &-month {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    &-item {
      display: inline-block;
      position: absolute;
      font-size: 12px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 22px;
    }
  }

  &-tooltip {
    display: flex;
    &-icon {
      width: 6px;
      height: 6px;
      background: #1ec692;
      border-radius: 50%;
      margin-right: 6px;
      position: relative;
      top: 8px;
    }
    &-title {
      font-size: 14px;
      margin-bottom: 5px;
    }
    &-content {
      font-size: 12px;
    }
  }
  .blue {
    background: rgba(50, 116, 246, 0.8);
    border-radius: 4px;
  }
  .green {
    background: rgba(30, 198, 146, 0.8);
    border-radius: 4px;
  }
  .organge {
    background: rgba(249, 121, 66, 0.8);
    border-radius: 4px;
  }
  .purple {
    background: rgba(181, 50, 246, 0.8);
    border-radius: 4px;
  }
  .yellow {
    background: rgba(246, 185, 50, 0.8);
    border-radius: 4px;
  }
  .deepGreen {
    background: rgba(84, 163, 120, 0.8);
    border-radius: 4px;
  }
  .pink {
    background: rgba(245, 108, 108, 0.8);
    border-radius: 4px;
  }
  .cyan {
    background: rgba(62, 177, 183, 0.8);
    border-radius: 4px;
  }
  .deepPurple {
    background: rgba(132, 104, 178, 0.8);
    border-radius: 4px;
  }
  .deepYellow {
    background: rgba(185, 164, 63, 0.8);
    border-radius: 4px;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);