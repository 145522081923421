.userCenter {
  background-color: white;
  padding: 24px;
  :global {
    h2 {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 40px;
    }
    .ant-row {
      line-height: 60px;
      min-height: 60px;
      .ant-col:first-child {
        text-align: right;
      }
    }
    .danger-tips {
      color: #ff574c;
      &.ant-btn {
        border: 1px solid #ff574c;
      }
    }
    button {
      width: 80px;
      padding: 4px 8px;
    }
    .user-center-wrap {
      width: 500px;
      .ant-form-item-label {
        line-height: 40px;
      }
      .ant-row {
        line-height: 40px;
      }
    }
    .ant-upload.ant-upload-select-picture-card {
      width: 80px;
      height: 80px;
      background-color: transparent;
      border: none;
    }
    .upload-wrap {
      position: relative;
      .mask {
        background-color: rgba(122, 122, 122, 0.6);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 80px;
        line-height: 80px;
        color: white;
        z-index: 10;
      }
    }
    #header-upload {
      position: absolute;
      top: -555555px;
      left: -555555555555px;
    }
    .desc {
      color: #adadad;
    }
    .ant-upload {
      align-items: baseline;
    }
  }
}

body {
  :global {
    .ant-form-item-label > label {
      height: 40px;
    }
  }
}

.timeZone {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    label {
      margin-right: 15px;
    }
  }
}

.userLayout {
  padding: 24px;
  min-width: 824px;
}

.customSubSlider {
  :global {
    .menu-title {
      font-weight: 700;
      text-align: left;
      background-color: white;
      padding: 10px 16px;
    }
    .ant-menu-title-content{
      text-indent: 40px;
    }
  }
}

//.authTemplate { background-color: white; padding: 20px;
//  .atHeader {display: flex; margin-bottom: 15px; justify-content: space-between; align-items: center; font-size: 20px; color: #262E3F; font-weight: 400;
//    .atCreator {height: 40px}
//  }
//  .atMore {
//    &:hover {color: #1cb887}
//  }
//  .atAction {white-space: nowrap;
//    :global {
//      .ant-btn {padding-left: 0}
//    }
//  }
//
//  .ateHeader {margin-bottom: 15px; font-weight: 500; color: #262E3F; font-size: 20px;
//    .ateBack {width: 36px !important; height: 36px !important; border-radius: 50%; padding: 0; display: inline-flex; justify-content: center; align-items: center; box-shadow: 0px 0px 9px -2px #BEC2CC; background-color: white; margin-right: 14px; color: #1cb887; border: 0}
//  }
//}

.authTemplateTitle { display: flex; justify-content: space-between }
.authTemplateEditTitle { display: flex;
  h3 { margin: 0 0 0 14px; line-height: 36px; font-size: 20px; font-weight: 500; color: #262e3f }
  .authTemplateEditTitleBack { width: 36px; height: 36px; text-align: center; background: #ffffff; border-radius: 500%; box-shadow: 0px 0px 9px -2px #bec2cc; cursor: pointer;
    img { width: 18px }
  }
}
.authTemplate {
  .authTemplateDetail {
    .authTemplateHeader { margin-bottom: 12px }
    .authTemplateDetailFooter { background-color: #ffffff; position: absolute; left: -24px; bottom: 0; width: calc(100% + 24px); padding: 12px 10px 12px 34px; box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.21) }
    .authTemplateName { font-size: 16px; color: #262e3f; line-height: 20px }
    .authTemplatePermission {
      position: relative;
      height: 100%;
      .authTemplateBox {
        display: block;
        height: 100%;
      }
      .authTemplatePermissionTable {
        .ant-table-cell {
          vertical-align: top;
        }
      }
      .authTemplatePermissionFooter {
        background-color: #ffffff;
        position: absolute;
        left: -24px;
        bottom: 12px;
        width: calc(100% + 24px);
        padding: 12px 10px 12px 34px;
        box-shadow: 0px 0px 12px -3px rgba(0, 0, 0, 0.21);
      }
      .authTemplatePermissionList {
        width: 100%;
      }
      .authTemplatePermissionItem {
        padding: 16px 0;
        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
        .ant-checkbox-wrapper {
          width: 140px;
          margin-right: 0;
        }
      }
    }
  }
  .authTemplateTable { margin-bottom: 24px; }
  .authTemplateFooter { background-color: #ffffff; position: absolute; left: 0; bottom: 0; width: 100%; padding: 28px 24px; }
  .authTemplateAction {
    .authTemplateActionSet { font-size: 14px; color: #1CB887 ; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer; }
    .authTemplateOperation { display: inline-block; height: 16px; width: 16px; cursor: pointer; }
  }
}
