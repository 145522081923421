.wl-form-item {
  .ant-form-item-label > label {
    height: 32px;
  }
}

// 覆盖index.less中的boder-right: 0
.wl-form-item,
.right-actions {
  .ant-input-number {
    border-right: 1px solid #d9d9d9;
  }

  .ant-input-number-disabled {
    border-color: #eee;
  }

  .ant-input-number:focus,
  .ant-input-number-focused {
    border-color: #42d4a1;
  }

  .ant-input-number-group-addon {
    padding-left: 11px;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);