.customFilter {
  padding: 20px 20px 1px 20px;
  :global {
    .ant-input-group.ant-input-group-compact {
      display: flex;
    }
    .ant-input-disabled {
      background-color: white;
    }
    .ant-input-group-compact .ant-input-affix-wrapper {
      border-left: none;
      &:focus {
        box-shadow: none;
      }
    }
    .ant-form-item {
      margin-bottom: 8px;
    }
    .filter-btns {
      text-align: right;
      .ant-space-horizontal {
        padding-right: 2px;
      }
    }
  }
}

.listName {
  display: flex;
  vertical-align: middle;
  align-items: center;
  span.name {
    flex: 1;
    vertical-align: middle;
    max-width: calc(100% - 22px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  :global {
    .anticon {
      vertical-align: middle;
      width: 22px;
      cursor: pointer;
    }
  }
}

.operaBtns {
  :global {
    .anticon {
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.paginationWrap {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  :global {
    .ant-pagination{
      color: #767f95;
    }
    .ant-pagination-item-active{
      border:none;
    }
    .ant-select-selection-search,.ant-select-selection-item{
      color:#767f95;
      input{
        color:#767f95;
      }
    }
    .ant-pagination-item{
      a{
        color:#767f95;
      }
    }
    .ant-pagination-item-active a{
      color:#1ec692;
    }
    .left-pagination {
      .ant-pagination-item,
      .ant-pagination-prev,
      .ant-pagination-next {
        display: none;
      }
      li.ant-pagination-options {
        &::before {
          content: '每页 ';
        }
        &::after {
          content: ' 条';
        }
      }
    }
  }
}

.wrap {
  :global {
    h1 {
      height: 28px;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
    }
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;
      .ant-btn:focus {
        color: rgba(0, 0, 0, 0.65);
        border-color: #d9d9d9;
      }
      .ant-btn.actived:focus {
        border: 1px solid #42d4a1;
        color: #42d4a1;
      }
      .ant-btn-primary:focus {
        color: white;
      }
    }
    .icon-btn {
      cursor: pointer;
    }
    .icon-btn > *:hover {
      filter: opacity(0.8);
    }
    .ant-dropdown-link {
      padding: 0;
    }
    .ant-table-body {
      min-height: 300px;
    }
    th.ant-table-cell {
      background-color: rgba(247, 246, 248, 1);
      height: 56px;
      &::before {
        display: none;
      }
      .ant-table-filter-column,
      .ant-table-column-sorters {
        justify-content: unset;
        .ant-table-column-title {
          flex: initial;
        }
      }
      .ant-table-column-sorter {
        margin-left: 5px;
      }
    }
    .ant-table-tbody > tr > td {
      border-bottom: 0;
    }
    .no-border-bottom-card .ant-card-head-title {
      padding: 20px 0;
    }
  }
}

.addModal {
  :global {
    // .ant-form-item-has-error{
    //   border:1px solid red;
    // }
    .search-label {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 40px;
      height: 40px;
      margin-bottom: 10px;
      .title {
        font-weight: 700;
        font-size: 16px;
      }
      .valid {
        color: red;
        margin-right: 16px;
      }
      .ant-btn-link {
        padding-right: 0;
      }
    }
    .desc {
      //   display: flex;
      font-size: 12px;
      color: #767f95;
      //   & > div {
      //     white-space: nowrap;
      //     overflow: hidden;
      //     max-width: 100%;
      //     overflow: hidden;
      //     text-overflow: ellipsis;
      //     &.username{
      //         min-width: 50px;
      //     }
      //   }
    }
    .btns {
      text-align: right;
    }
    .ant-form-item-explain {
      display: none;
    }
    .ant-form-item {
      margin-bottom: 20px;
    }
    .project-item-card {
      padding: 12px 16px;
      background: #f8f8f8;
      border-radius: 2px;
      border: 1px solid #eeeeee;
      cursor: pointer;
      position: relative;
      height: 100%;
      .title {
        font-size: 14px;
        font-weight: 700;
        color: #262e3f;
        line-height: 22px;
        margin-right: 9px;
      }
      .count {
        color: #42d4a1;
        background: rgba(30, 198, 146, 0.1);
        border-radius: 2px;
        padding: 0px 8px 0px 8px;
        font-weight: 700;
      }
      .desc {
        margin-top: 8px;
      }
    }
    .project-item-card.active {
      background: rgba(30, 198, 146, 0.04);
      border-radius: 2px;
      border: 1px solid #1ec692;
      img {
        position: absolute;
        right: -1px;
        bottom: -1px;
      }
    }
  }
}

.unLockModal {
  :global {
    .ant-modal-confirm-btns {
      margin-top: 0;
    }
    .ant-btn {
      border: 1px solid #f97942;
      color: #f97942;
      &:hover {
        color: #f97942;
      }
    }
    .ant-list-grid .ant-col > .ant-list-item {
      border-bottom: 0;
      background-color: rgba(249, 121, 66, 0.06);
      color: #f97942;
      padding: 11px;
    }
    .ant-btn-primary {
      background-color: #f97942;
      color: white;
      &:hover {
        color: white;
      }
    }
  }
}

.createSchemeProjectList {
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #cfd6e8;
    border-radius: 6px;
  }
}

.listPage {
  :global {
    .ant-table-cell{
      &::before{
        display: none;
      }
    }
    .full-screen-table {
      height: calc(100% - 55px);
    }
    .ant-pagination-total-text {
      margin-right: 20px;
    }
    .ant-pagination-options-quick-jumper {
      margin-left: 20px;
    }
  }
}

.filterDownCheckboxWrap{
  padding: 8px;
   width: 155px;
  :global{
    .ant-checkbox-wrapper{
      width: 100%;
      clear: both;
      margin: 0;
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      white-space: nowrap;
      cursor: pointer;
      transition: all 0.3s;
      &:hover{
        background-color: #f5f5f5;
      }
    }
  }
}