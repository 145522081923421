.tab {
  background-color: white;
  min-width: 5rem;
  padding: 0.5rem 1rem;
  text-align: center;
  margin-right: 1rem;
  border-radius: 5px;
  position: relative;
  border: 1px dotted @border-color-base;
  color: @text-color;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;

    width: 0;
    height: 0;
    border: 5px solid transparent;
  }
}

.tab-selected {
  background-color: @primary-color;
  color: white;
  border: none;

  &:after {
    border: 5px solid white;
    border-top: 5px solid @primary-color;
  }
}

.tab-finished {
  .finish-icon {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}

.tab-disabled {
  filter: grayscale(100%);
  background-color: rgba(0,0,0,0.1);
}

.tab-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);