.equipment-station {
  .ant-tag-green {
    padding: 4px 19px;
    border: none;
    background: rgba(30, 198, 146, 0.1);
    color: #1cb887;
    font-weight: bold;
  }
  &-title {
    margin: 0;
    font-size: 20px;
    font-family: Helvetica;
    color: #262e3f;
    line-height: 28px;
  }
  &-create {
    height: auto !important;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    &-header {
      padding: 20px 24px;
    }
    &-content {
      position: relative;
      flex: auto;
    }
    &-footer {
      padding: 30px 24px;
      text-align: center;
    }
  }
  &-steps {
    width: 680px !important;
    margin: 0 auto !important;
    padding: 30px 0 !important;
  }
  &-detail {
    height: 100%;
    min-width: 1192px;
    display: flex;
    justify-content: space-between;
    &-half {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-self: flex-start;
      overflow: hidden;
    }
    &-left {margin-right: 16px;}
  }
  &-position {
    display: inline-block;
    min-width: 386px;
    flex: 1 1 386px;
    height: 444px;
    margin-right: 16px;
    padding: 8px 16px;
    background: #ffffff;
    border-radius: 4px;
    &-title {
      font-size: 14px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 40px;
    }
    &-address {
      margin-bottom: 12px;
      font-size: 14px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 32px;
      img {
        width: 24px;
      }
    }
    &-coordinate {
      color: #767f95;
    }
    &-content {
      display: flex;
      margin-bottom: 12px;
      &-left {
        width: 139px;
        height: 80px;
        margin-right: 16px;
        padding: 16px 10px;
        background: #f8f8f8;
        border-radius: 4px;
        border: 1px solid #eeeeee;
      }
      &-right {
        flex: auto;
      }
    }
    &-row {
      margin-bottom: 8px;
      font-size: 14px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 32px;
      &:last-child {
        margin-bottom: 0;
      }
      &-value {
        color: #767f95;
      }
    }
  }
  &-statistic {
    min-width: 186px;
    flex: 0 0 186px;
    height: 444px;
    &-item {
      display: flex;
      height: 99px;
      padding: 22px 9px;
      margin-bottom: 16px;
      background: #ffffff;
      border-radius: 4px;
      &:last-child {
        margin: 0;
      }
    }
    &-icon {
      width: 26px;
      height: 26px;
      margin-right: 5px;
    }
    &-content {
      flex: auto;
    }
    &-title {
      margin: 0;
      font-size: 12px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 14px;
    }
    &-footer {
      display: flex;
      align-items: baseline;
    }
    &-unit {
      margin: 0 0 0 10px;
      font-size: 14px;
      font-family: Helvetica;
      color: #98a0b3;
      line-height: 22px;
    }
  }
  &-equipment {
    display: inline-block;
    min-width: 386px;
    flex: 1 1 386px;
    height: 444px;
    margin-left: 16px;
    padding: 8px 16px;
    background: #ffffff;
    border-radius: 4px;
    &-header {
      display: flex;
      margin: 0;
      font-size: 14px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 40px;
    }
    &-title {
      flex: auto;
    }
    &-more {
      font-size: 12px;
      color: #1cb887;
      cursor: pointer;
    }
    &-date {
      font-size: 14px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 32px;
      &-value {
        color: #767f95;
      }
    }
    &-tips {
      font-size: 14px;
      font-family: Helvetica;
      color: #767f95;
      line-height: 32px;
      .online {
        color: #1ec692;
      }
      .offline {
        color: #767f95;
      }
    }
  }
  &-curve {
    min-width: 588px;
    flex: 1 1 100%;
    height: 290px;
    margin-top: 16px;
    padding: 8px 16px;
    background: #ffffff;
    border-radius: 4px;
    &-header {
      display: flex;
      padding: 10px 0;
      margin-bottom: 10px;
    }
    &-content {
      position: relative;
    }
    &-title {
      flex: auto;
      margin: 0;
      font-size: 14px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 22px;
    }
    &-extra {
      margin: 0;
      font-size: 14px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 22px;
    }
    &-time {
      color: #767f95;
    }
    &-stations {
      margin-bottom: 10px;
    }
    &-station {
      display: inline-block;
      line-height: 32px;
      margin-right: 20px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &-color {
        display: inline-block;
        width: 16px;
        height: 16px;
        // background: #1ec692;
        border-radius: 2px;
        vertical-align: middle;
      }
      &-name {
        margin-left: 10px;
        font-size: 12px;
        font-family: Helvetica;
        color: #767f95;
        line-height: 22px;
      }
    }
  }
  &-monitor {
    width: 100%;
    min-width: 588px;
    height: 290px;
    margin-top: 16px;
    padding: 8px 16px;
    background: #ffffff;
    border-radius: 4px;
    &-header {
      display: flex;
      padding: 10px 0;
      margin-bottom: 10px;
    }
    &-content {
      position: relative;
      width: 100%;
    }
    &-title {
      flex: auto;
      margin: 0;
      font-size: 14px;
      font-family: Helvetica;
      color: #262e3f;
      line-height: 22px;
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);