#topology-element {
  .cls-1,.cls-11,.cls-16,.cls-9{fill:none;}.cls-2{fill:#afbfe2;}.cls-3{fill:#80a0d8;}.cls-4{fill:#c3dadd;}.cls-5{fill:#d0e2ef;}.cls-6{clip-path:url(#clip-path);}.cls-7{fill:#e8e8e8;stroke-width:8px;}.cls-7,.cls-8,.cls-9{stroke:#fff;}.cls-7,.cls-8{stroke-miterlimit:10;}.cls-8{fill:#efefef;}.cls-11,.cls-16,.cls-9{stroke-linecap:round;stroke-linejoin:round;}.cls-10{clip-path:url(#clip-path-2);}.cls-11{stroke:#f9f9f9;stroke-width:0.5px;}.cls-12{opacity:0.7;}.cls-13{opacity:0.4;}.cls-14{fill:#96bcb5;}.cls-15{fill:#7ca097;}.cls-16{stroke:#1ec692;stroke-width:4px;}
  #pv_text,#gw_text,#user_transformer_text,#gf_transformer_text,#bear_text,#battery_text {
    opacity: 0;
    pointer-events: none;
    font-family: "阿里巴巴普惠体", 'Alibaba PuHuiTi Regular', 'alibaba-puhuiti', 'PingFangSC-Regular', 'Microsoft YaHei', 'MicrosoftYaHei', 'PuHuiTi', serif;
  }
  #pv, #battery {
    cursor: pointer;
  }
  #gw_transformer_pv_fill,#battery_bear_fill,#transformer_pv_low_pv_fill,#gw_transformer_pv_fill_low {
    stroke-width: 5px;
    stroke-dasharray: 20 300;
    animation: fxlineMove 2s linear infinite;
  }
  #transformer_pv_pv_fill,#gw_transformer_bear_fill,#transformer_bear_bear_fill,#gw_transformer_battery_fill,#transformer_battery_battery_fill,#pv_battery_fill,#pv_bear_fill {
    stroke-width: 5px;
    stroke-dasharray: 20 300;
    animation: zxlineMove 2s linear infinite;
  }
  @keyframes zxlineMove {
    100% {
      stroke-dashoffset:0;
    }
    0%{
      stroke-dashoffset:300;
    }
  }
  @keyframes fxlineMove {
    0% {
      stroke-dashoffset:0;
    }
    100%{
      stroke-dashoffset:300;
    }
  }

}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);