.remove-modal {
  .ant-modal-header {
    padding: 24px 24px 12px !important;
    border-bottom: none;
  }
  .ant-modal-body {
    margin: 0 0 8px 0 !important;
    padding: 8px 24px 10px !important;
  }
  .ant-modal-footer {
    padding: 16px 24px !important;
    border-top: none;
  }
  &-title {
    margin: 0;
    font-size: 16px;
    color: #262e3f;
    line-height: 24px;
    img {
      height: 24px;
      margin-right: 10px;
    }
    span {
      vertical-align: middle;
    }
  }
  &-content {
    min-height: 44px;
    p {
      margin: 0;
      font-size: 14px;
      color: #767f95;
      line-height: 22px;
    }
  }
  &-footer {
    .ant-btn-default {
      color: #F97942;
      border-color: #F97942;
    }
    .ant-btn-primary {
      border-color: #F97942;
      background: #F97942;
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);