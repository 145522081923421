.container {
  overflow: auto;
  height: 100%;

  // 隐藏滚动条
  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin card() {
  width: 100%;
  height: 130px;
  padding: 10px 24px;
  overflow: hidden;
  border-radius: 4px;
  background-color: #fff;
}

//横排
.horizontalCenter {
  @include card();
  display: flex;
  align-items: center;
}

//竖排
.verticalCenter {
  @include card();
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
