.settings {
  height: calc(100% - 50px);

  &-info {
    &-form {
      max-width: 432px;
    }
  }

  &-change {
    &-title {
      margin-bottom: 12px;
      font-size: 16px;
      color: #262e3f;
      line-height: 22px;
    }

    &-tips {
      margin-bottom: 41px;
      font-size: 14px;
      color: #767f95;
      line-height: 22px;
    }
  }

  &-item {
    background: #ddd;
    padding: 2px;
    width: 30px;
    height: 30px;
    align-items: center;
    margin-left: -8px;
    color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 6px;
  }

  &-create {
    font-size: 14;
    color: #1cb887;
    display: flex;
    align-items: center;
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);