.pagination-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 24px;

  .ant-pagination {
    color: #767f95;
  }
  .ant-pagination-item-active {
    border: none;
  }
  .ant-select-selection-search,
  .ant-select-selection-item {
    color: #767f95;
    input {
      color: #767f95;
    }
  }
  .ant-pagination-item {
    a {
      color: #767f95;
    }
  }
  .ant-pagination-item-active a {
    color: #1ec692;
  }
  .left-pagination {
    .ant-pagination-item,
    .ant-pagination-prev,
    .ant-pagination-next {
      display: none;
    }
    li.ant-pagination-options {
      &::before {
        content: '每页 ';
      }
      &::after {
        content: ' 条';
      }
    }
  }
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);