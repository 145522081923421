body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#root {
  height: 100%;

  > .ant-layout {
    height: 100%;
  }

  > div,
  section {
    height: 100%;

    > .ant-spin-container {
      height: 100%;
    }

    > .ant-layout {
      height: 100%;
    }
  }
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  line-height: 1.9;
}

.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 0;
  padding-bottom: 8px;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.justify-space-between {
  justify-content: space-between;
}

.justify-flex-end {
  justify-content: flex-end;
}

.custom-margin-left {
  margin-left: 1rem;
}

.custom-margin-right {
}

.custom-margin-top {
}

.custom-margin-bottom {
}

.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .ant-pagination-total-text {
    flex-grow: 1;
  }

  .ant-pagination-item {
    background-color: transparent;
    border: none;
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    > .ant-pagination-item-link {
      background-color: transparent;
      border: none;
    }
  }
}

.custom-card-table {
  padding-left: 10px;
  padding-right: 10px;

  .ant-table-body > table {
    margin-top: -1.2rem;
    border-spacing: 0 1rem;

    tbody {
      tr:nth-child(2) {
        margin-top: 0 !important;
      }
    }
  }

  .ant-table-tbody {
    tr {
      box-shadow: 0 2px 10px 0 rgba(27, 41, 60, 0.08);
      padding-top: 2rem;
      padding-bottom: 2rem;
      height: 5rem;
    }
  }
}

a,
a:hover {
  color: @primary-color;
}

.ant-table-body {
  overflow-y: auto !important;
}

.ant-space-item {
  display: flex;
  align-items: center;
}

.form-cascader {
  input {
    height: 32px;
  }
}

.no-padding-card {
  .ant-card-head,
  .ant-card-body {
    padding-left: 0;
    padding-right: 0;
  }
}

.ant-btn-link {
  color: @primary-color;
}
a.ant-typography,
.ant-typography a {
  color: @primary-color;

  &:hover {
    color: @primary-color;
  }
}

button.ant-btn {
  border-radius: 4px;
}

.ant-layout-header {
  height: 60px;
}

.ant-modal-root .ant-modal-body {
  padding: 16px 24px;

  .ant-modal-confirm-body {
    .anticon-info-circle {
      height: 24px;
      line-height: 1;
      display: none;
      > svg {
        width: 16px;
        height: 16px;
      }
    }
    h2 {
      height: 24px;
      color: #262e3f;
      font-size: 16px;
    }
  }
  .ant-modal-confirm-content {
    padding: 16px 0;

    .ant-form-item {
      margin-bottom: 20px;
    }
  }
}

.align-right-full {
  width: 100%;
  text-align: right;
  display: inline-block;
}

.ant-empty {
  .ant-empty-image {
    background: url('./assets/table-empty.png') no-repeat center center;
    background-size: 149px;
    min-height: 164.5px;
    width: 140px;
    margin: 0 auto;
    position: relative;

    svg {
      opacity: 0;
    }
  }
  .ant-empty-description {
    opacity: 0;
  }
}

.ant-select-dropdown-empty {
  .ant-empty {
    .ant-empty-image {
      //background-size: 70px;
      //min-height: 82.25px;
    }
  }
}

.right-actions {
  .ant-table-body {
    overflow-y: scroll !important;
  }

  thead.ant-table-thead {
    tr:first-child {
      th:nth-last-child(2) {
        //right: 0 !important;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
    transition: all ease-in-out 0.3s;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: transparent;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #ddd;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: #999;
    /*当焦点不在当前区域滑块的状态*/
  }
}

.ant-cascader-picker-disabled {
  background: #f8f8f8;
  border: 1px solid #eeeeee;

  .ant-input-disabled {
    border: none;
  }
}

.ant-input-number-disabled,
.ant-picker.ant-picker-disabled,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input-affix-wrapper-disabled {
  background: #f8f8f8;
  border: 1px solid #eeeeee;
}

.hover-outer {
  .hover-inner {
    opacity: 0;
    transition: opacity 0.2s;
  }
  &:hover {
    .hover-inner {
      opacity: 1;
    }
  }
}

.ant-btn-dangerous.ant-btn-primary {
  background-color: #f97942;
  color: white;
  border-color: #f97942;
  &:hover {
    background-color: #f97942;
    opacity: 0.8;
  }
  &:focus {
    background-color: #f97942;
  }
}

.ant-btn-dangerous {
  color: #f97942;
  border-color: #f97942;
  background-color: white;
  &:hover {
    color: #f97942;
    border-color: #f97942;
    background-color: white;
    opacity: 0.8;
  }
  &:focus {
    color: #f97942;
    border-color: #f97942;
    background-color: white;
  }
}

.menu-popover-overlay {
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 8px 0;

    .ant-menu-inline {
      padding: 0 !important;
      li {
        margin: 0;
        height: 34px;
        text-align: center;
        min-width: 90px;
        padding: 0 20px !important;

        &:hover {
          background-color: #f9f8f9;
        }
      }
    }
  }
}

.ant-table-filter-column,
.ant-table-column-sorters {
  justify-content: flex-start;
}
.ant-table-column-sorter {
  margin-left: 5px;
}
.ant-table-column-title {
  white-space: nowrap;
  flex-grow: 0;
  font-weight: 600;
}

.ant-table-thead {
  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    font-weight: 600;
  }
}

.ant-table-body {
  padding-bottom: 8px;
}

.ant-table-thead > tr:not(:last-child) > th[colspan] {
  .ant-table-filter-column {
    justify-content: center;
  }
}

.custom-slider {
  .ant-slider-dot-active {
    border: none;
    background-color: white;
    width: 4px;
    height: 4px;
    top: 0;
  }
  .ant-slider-dot {
    border: none;
    background-color: white;
    width: 4px;
    height: 4px;
    top: 0;
  }

  .ant-slider-track {
    background-color: rgba(207, 214, 232, 1) !important;
  }

  &.min-15 .ant-slider-track {
    width: 16.129% !important;
  }
  &.min-20 .ant-slider-track {
    width: 32.2581% !important;
  }
  &.min-30 .ant-slider-track {
    width: 48.3871% !important;
  }
  &.min-60 .ant-slider-track {
    width: 96.7742% !important;
  }

  .ant-slider-rail {
    color: rgba(247, 246, 248, 1) !important;
  }
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th {
  border-bottom: none;
}

.bordered-table {
  .ant-table-tbody > tr > td,
  .ant-table-thead > tr > th {
    border-bottom: 1px solid #f0f0f0;
  }
}

.percent-slider {
  .ant-slider-rail {
    background-color: rgba(50, 116, 246, 1) !important;
  }
}

.no-border-bottom-card {
  .ant-card-head-title {
    padding: 24px 0;
  }
  .ant-card-head {
    border-bottom: none;
  }
}

.manage-tabs {
  .ant-tabs-tab-btn {
    font-size: 14px;
    font-weight: bold;
    color: #262e3f;
  }
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 16px;
  color: #262e3f;
  font-weight: bold;
}

.mask-transparent {
  .ant-drawer-mask {
    background-color: transparent;
  }
}

.danger-modal {
  p {
    color: #767f95;
  }
}

.ant-table-filter-dropdown {
  padding: 0.5rem;
}
.ant-table-filter-dropdown-btns {
  border-top: none;

  .ant-btn {
    width: 64px;
    height: 32px;
  }
  .ant-btn-primary {
    margin-left: 0.5rem;
  }
  .ant-btn-link {
    border: 1px solid @primary-color;
    color: @primary-color;

    &:hover {
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }
}

.table-minimal,
.jump-right {
  .ant-table-pagination-right {
    position: relative;
    padding-right: 120px;

    .ant-pagination-options-quick-jumper {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}
.manage-table,
.table-minimal {
  .ant-pagination-total-text {
    order: -1;
    flex-grow: 0 !important;
  }
  .ant-pagination-options {
    order: -1;
    flex-grow: 1;
  }
}

.label-low {
  .ant-form-item-label {
    padding: 0;
    line-height: 1;
    margin-bottom: 1rem;
    label {
      height: auto;
    }
  }
}

.ant-layout {
  position: relative;
}

.absolute-fill {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ant-input-number {
  border-right: none;
}

.ant-input-number-group-addon {
  background-color: white;
  padding-left: 0;
}

.weather-custom-radio-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.resource-reckon {
  .amap-info-close {
    display: none;
  }
  .ant-slider-rail {
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
  }

  .ant-slider-mark-text {
    color: white;
    text-shadow: rgb(0 0 0) 1px 1px 5px;
  }

  .ant-slider-with-marks {
    margin-bottom: 10px;
  }

  .amap-info {
    pointer-events: none !important;
  }
}

.map-popover {
  max-width: 450px;
  pointer-events: all;

  .map-popover-label {
    font-size: 14px;
    color: #262e3f;
    line-height: 22px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .province-scan-button {
      pointer-events: all;
      cursor: pointer;
      background-color: @primary-color;
      color: white;
      border-radius: 4px;
      padding: 2px 6px;
    }
  }
  .map-popover-item {
    width: 33%;
    text-align: center;
    padding: 10px;
    width: 150px;
  }
  .map-popover-value {
    font-size: 18px;
    font-weight: 500;
    color: #1cb887;
    line-height: 20px;
  }
  .map-popover-unit {
    font-size: 10px;
    font-weight: 400;
    color: #767f95;
    line-height: 14px;
  }
  .map-popover-name {
    font-size: 12px;
    font-weight: 400;
    color: #98a0b3;
    line-height: 18px;
    flex-shrink: 0;
    white-space: nowrap;
  }

  .e-radix {
    display: inline-block;
    font-size: 8px;
    vertical-align: top;
    line-height: 8px;
  }
}

.left-pagination {
  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    display: none;
  }
}

.roof-content {
  align-items: center;
  justify-content: center;
  padding-right: 0;

  .map-popover-item {
    width: 50%;
  }
  .map-popover-value {
    white-space: nowrap;
  }
}

.time-dropdown-picker {
}

.ant-modal-wrap,
.ant-modal-mask,
.ant-dropdown-placement-bottomRight {
  //z-index: 9999999;
  z-index: 1000;
}

.result-config {
  .info-body {
    .ant-card-bordered {
      border: none;
      overflow: hidden;

      .ant-card-body {
        border-bottom: 1px solid #f0f0f0;
      }
    }
  }
}

.dotted-row {
  .border-bottom-dotted-line {
    position: absolute;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, #d6dbec 0%, #d6dbec 50%, transparent 50%);
    background-size: 8px 2px;
    background-repeat: repeat-x;
  }
}

#project-map {
  .amap-icon img {
    width: 25px;
    height: 34px;
  }

  .amap-marker-label {
    border: 0;
    background-color: transparent;
  }

  .info {
    position: relative;
    margin: 0;
    top: 0;
    right: 0;
    min-width: 0;
  }
}

#bear-plot {
  .bear-content {
    height: auto !important;
    overflow-y: auto;
  }

  .hide-on-plot {
    display: none;
  }
}

.ant-layout-sider {
  background: transparent !important;
}

#bg-white {
  > div {
    background-color: white;
  }
}

.hide-footer {
  .ant-modal-confirm-btns {
    display: none !important;
  }
}

.progress-percent {
  .ant-progress-text {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-left: 6px;

    &:after {
      content: '%';
      font-size: 12px;
    }
  }
}

.fill-color-green {
  .ant-progress-inner.ant-progress-circle-gradient {
    background-color: @primary-color;
    .ant-progress-circle-trail {
      stroke-width: 0;
    }
  }
  .ant-progress-text {
    svg {
      color: white;
      font-size: 64px;
    }
  }
}

.mini-tool-bar {
  .ant-spin-container {
    width: 100%;
    height: 100%;
    background-color: white;

    .ant-tabs-tabpane {
      min-height: calc(100vh - 180px);
      max-height: calc(100vh - 180px);
      overflow-y: auto;
    }

    &.ant-spin-blur {
      opacity: 1;
    }
    .ant-tabs-tab {
      font-size: 12px;
      font-weight: 400;
      color: #262e3f;
    }

    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
      border: none;
    }

    .ant-tabs-tab.ant-tabs-tab-active {
      font-size: 14px;
      font-weight: 400;
      color: #1cb887;
      border-radius: 4px;
      border-left: 1px solid #ebebeb;
      border-right: 1px solid #ebebeb;
      border-top: 1px solid #ebebeb;
    }
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    background-color: rgba(247, 246, 248, 1);
  }

  .ant-radio-wrapper {
    white-space: nowrap;

    span {
      padding: 0;

      &:nth-child(2) {
        padding-left: 4px;
      }
    }
  }

  .ant-form-item {
    margin-bottom: 1rem;
  }

  .split-line {
    margin-left: -1rem;
    margin-right: -1rem;
    background-color: #ebebeb;
    height: 1px;
    margin-bottom: 0.5rem;
  }

  .ant-form-item-label {
    label {
      height: 30px;
    }
  }
  .ant-form-item-label > label::after {
    content: '';
  }

  .ant-form-item-label > label::before {
    content: '' !important;
    margin-right: 0 !important;
  }

  .action-buttons {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;
    }
  }

  .ant-form-item-label {
    text-align: left;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-input-number {
    border-right: none;
  }

  .calc-result {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;

    .calc-result-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .calc-chart {
        width: 100%;
        padding-left: 16px;
        padding-right: 12px;
        margin-top: 7px;
        .calc-chart-title {
          font-size: 14px;
          font-weight: 400;
          color: #262e3f;
        }
      }
      .calc-result-title {
        flex-shrink: 0;
        width: 100%;
        background-color: rgba(247, 246, 248, 1);
        height: 40px;
        line-height: 40px;
        padding-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #262e3f;
      }

      .item-content:nth-child(2n) {
        .v-split-line {
          position: absolute;
          width: 1px;
          right: 0;
          top: 24px;
          bottom: 24px;
          background-color: #ebebeb;
        }
      }
      .item-content {
        position: relative;
        width: 50%;
        height: 78px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .item-content-value {
          font-size: 20px;
          font-weight: 500;
          color: #262e3f;
          .item-content-unit {
            font-size: 10px;
            font-weight: 400;
            color: #767f95;
          }
        }
        .item-content-tips {
          font-size: 12px;
          font-weight: 400;
          color: #98a0b3;
        }
      }
    }
  }
}

.ellipsis-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  span {
    width: 100%;
    overflow: hidden;
    border-bottom: 2px solid #1ec692;

    &.disabled {
      border-bottom-color: #bec2cc;
    }
  }
}

.absolute-top {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 16px;
}

.primary-mark {
  color: #1ec692;
}

.low-strength {
  pointer-events: none;
  .ant-slider-track {
    background-color: #f5222d;
  }
  .ant-slider-handle {
    border-color: #f5222d;
    display: none;
  }
}
.mid-strength {
  pointer-events: none;
  .ant-slider-track {
    background-color: #faad14;
  }
  .ant-slider-handle {
    border-color: #faad14;
    display: none;
  }
}
.high-strength {
  pointer-events: none;
  .ant-slider-track {
    background-color: #1ec692;
  }
  .ant-slider-handle {
    border-color: #1ec692;
    display: none;
  }
}

.weather-table-total {
  table {
    border-left: 1px solid #bfc3cd !important;
    border-top: 1px solid #bfc3cd !important;

    thead {
      th {
        background-color: #f8f8f8 !important;
      }
    }
    tbody {
      tr {
        td:nth-child(1) {
          background-color: #f8f8f8 !important;
        }
      }
    }
  }
  td,
  th {
    border-bottom: 1px solid #bfc3cd !important;
    border-right: 1px solid #bfc3cd !important;
  }

  td {
    &.ant-table-cell {
      font-size: 14px;
      font-weight: 400;
      color: #262e3f;
      .data-name {
        color: #262e3f;
        .data-name-unit {
          font-size: 12px;
          color: #98a0b3;
          margin-left: 0.2rem;
        }
      }
    }
  }
}

.border-number-right.ant-input-number {
  border-right: 1px solid #d9d9d9 !important;
}

.edge-inner-spin {
  .ant-spin-nested-loading,
  .ant-spin-container {
    height: 100%;
  }
}

.klf-date-range {
  .ant-select-selector {
    padding: 0 5px !important;
    text-align: center;
  }
}

.klf-date-range-selector {
  .ant-select-item {
    padding: 5px;
    text-align: center;
  }
}

.default-select-date {
  .ant-picker-header-view {
    button:first-child {
      position: relative;
      color: transparent;

      &:after {
        content: '典型年';
        color: black;
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
}

.authModal {
  height: 100px;
  .ant-modal-header {
    border-bottom: 0;
  }
  .ant-modal-footer {
    border-top: 0;
  }
  .ant-input {
    width: 300px;
  }
}

.main-card {
  border: 0;
  margin-top: 0 !important;
  > .ant-card-head {
    padding: 0;
    border: none;

    .ant-card-head-title {
      font-size: 20px;
      color: #262e3f;
      line-height: 28px;
      padding: 24px;
      font-weight: bold;
    }
  }

  > .ant-card-body {
    padding: 0 24px 24px 24px;
    position: relative;
  }

  .main-tab {
    .ant-tabs-tab-btn {
      height: 22px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &.full-height {
    height: 100%;

    .ant-card-body {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.required:before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-btn-link {
  color: @primary-color !important;
}

.equipmentContainer {
  .ant-tree .ant-tree-treenode {
    width: 100%;
  }
  .ant-tree .ant-tree-node-content-wrapper {
    width: 100%;
    padding: 4px 0;
    font-size: 14px;
  }
  .ant-tree-switcher .ant-tree-switcher-icon,
  .ant-tree-switcher .ant-select-tree-switcher-icon {
    margin-top: 12px;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    width: 100%;
    padding: 4px 0;
    background-color: #f9f8f9;
    font-size: 14px;
  }
}

.hide-required {
  .ant-form-item-required {
    height: 32px;
    &:before {
      display: none !important;
    }
  }

  .ant-form-item {
    margin-bottom: 1rem;
    align-items: flex-start;
  }

  .split-line {
    background-color: #ebebeb;
    height: 1px;
    margin-bottom: 1rem;
  }

}

.container-content {
  padding: 0 1rem;
  .container-title {
    margin: 0 -1rem;
    padding: 0 1rem;
    height: 40px;
    background: #F7F6F8;

    font-size: 14px;
    color: #262E3F;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &.calc-result {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 20px;

    .calc-result-section {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .calc-chart {
        width: 100%;
        padding-left: 16px;
        padding-right: 12px;
        margin-top: 7px;
        .calc-chart-title {
          font-size: 14px;
          font-weight: 400;
          color: #262e3f;
        }
      }
      .calc-result-title {
        flex-shrink: 0;
        width: 100%;
        background-color: rgba(247, 246, 248, 1);
        height: 40px;
        line-height: 40px;
        padding-left: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #262e3f;
      }

      .item-content:nth-child(2n) {
        .v-split-line {
          position: absolute;
          width: 1px;
          left: 0;
          top: 24px;
          bottom: 24px;
          background-color: #ebebeb;
        }
      }
      .item-content {
        position: relative;
        width: 50%;
        height: 78px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .item-content-value {
          font-size: 20px;
          font-weight: 500;
          color: #262e3f;
          .item-content-unit {
            font-size: 10px;
            font-weight: 400;
            color: #767f95;
          }
        }
        .item-content-tips {
          font-size: 12px;
          font-weight: 400;
          color: #98a0b3;
        }
      }
    }
  }
}
.title-circle {
  width: 23px;
  height: 23px;
  background-color: #1EC692;
  border-radius: 15px;
  display: inline-block;
  margin-right: .5rem;
  line-height: 23px;
  text-align: center;
  color: white;
}

.calc-selected-row {
  background-color: rgba(30, 198, 146, .1);
}


.popover-title-dom {

}

.number-primary {
  color: #1EC692;
  font-size: 1.2rem;
}


.max-size-modal.ant-modal {
  max-width: 100vw;
  max-height: 100vh;
  top: 0;
}

@primary-color: #1ec692;@link-color: #1890ff;@blue-color: #1890ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color:  rgba(0, 0, 0, 0.85);@text-color: rgba(0, 0, 0, 0.65);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@border-radius-base: 2px;@border-color-base: #d9d9d9;@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);